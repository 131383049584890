<template>
	<div>
		<new-page-gen v-if="options.value.formId" :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'
import axiosApiClient from '@/api/axiosApiClient'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

const { VUE_APP_VERSION } = process.env

function formToJsonSchema(form) {
	const schema = {
		type: 'object',
		additionalProperties: false,
		required: [],
		properties: {}
	}

	schema.properties = form.fields.reduce((acc, value) => {
		if (value.isRequired === true) {
			schema.required.push(value.tag)
		}
		let propSchema

		// possible values:
		// CHECKBOX, TEXT, DROPDOWN, RADIO, TIME, BARCODE, CLIENT_SELECT,
		// COUNTER, INLINE_FORM, PHOTO, DATE
		switch (value.type) {
			case 'BARCODE': {
				propSchema = {
					type: 'object',
					additionalProperties: false,
					required: ['value', 'meta'],
					properties: {
						value: {
							type: 'string'
						},
						meta: {
							type: 'object',
							additionalProperties: false,
							required: ['clientId'],
							properties: {
								clientId: {
									type: 'string',
									minLength: 3,
									maxLength: 15
								},
								format: {
									type: 'string',
									default: 'MANUAL',
									enum: [
										'ALL',
										'AZTEC',
										'CODABAR',
										'CODE_39',
										'CODE_93',
										'CODE_128',
										'DATA_MATRIX',
										'EAN_8',
										'EAN_13',
										'ITF',
										'MAXICODE',
										'PDF_417',
										'QR_CODE',
										'RSS_14',
										'RSS_EXPANDED',
										'UPC_A',
										'UPC_E',
										'UPC_EAN_EXTENSION',
										'MANUAL'
									],
									readOnly: true // let's see if we want to open this later
								}
							}
						}
					}
				}

				if (form.clientId !== null) {
					// if the form is client specific let's hardcode the clientId
					propSchema.properties.meta.properties.clientId.default =
						form.clientId
					propSchema.properties.meta.properties.clientId.readOnly = true
				}

				break
			}
			case 'CHECKBOX': {
				propSchema = {
					type: 'boolean'
				}
				break
			}
			case 'TIME': {
				propSchema = {
					type: 'string',
					format: 'time'
				}
				break
			}
			case 'COUNTER': {
				propSchema = {
					type: 'number',
					minimum: 0
				}
				break
			}
			case 'PHOTO': {
				propSchema = {
					type: 'string',
					format: 'uri',
					file: true
				}
				break
			}
			case 'DATE': {
				propSchema = {
					type: 'string',
					format: 'date-time'
				}
				break
			}
			default: {
				propSchema = {
					type: 'string'
				}
			}
		}

		propSchema = {
			...propSchema,
			description: value.tag,
			title: _.get(value, 'translations', [
				{
					locale: 'en-HK',
					value: value.id
				}
			]).find(t => t.locale === 'en-HK').value
		}

		if (value.options.length > 0) {
			propSchema.enum = value.options.map(o => o.tag)
		}

		if (value.isArray === true) {
			propSchema = {
				type: 'array',
				items: propSchema
			}

			/**
			 * Form submission can only have one order number
			 *
			 * @see deliveries.createDeliveries 'INVALID_PARAMETERS' error
			 */
			if (value.tag === 'ORDER_NO') {
				propSchema.maxItems = 1
			}
		}

		acc[value.tag] = propSchema

		return acc
	}, {})

	return schema
}

export default {
	name: 'FormSubmissionsNew',
	components: { NewPageGen },
	formToJsonSchema,
	data() {
		const config = {
			options: {
				resource: 'form-submissions',
				autoInit: false,
				value: {
					formId: null
				},
				schemaModifier: async (data, options) => {
					const formResponse = await axiosApiClient({
						url: `/forms/${options.value.formId}`
					})

					delete data.properties.deletedAt
					delete data.properties.submitterRef

					data.properties.fields = formToJsonSchema(formResponse.data)

					if (formResponse.data.clientId === null) {
						// common forms only needs `formId`
						data.properties.formId.readOnly = true
						delete data.properties.tag
						delete data.properties.clientId
						delete this.$data.options.value.tag
						delete this.$data.options.value.clientId
					} else {
						// client specific forms use `clientId` and `tag`
						delete data.properties.formId
						data.properties.tag.readOnly = true
						data.properties.clientId.readOnly = true
						this.$data.options.value.tag = formResponse.data.tag
						this.$data.options.value.clientId =
							formResponse.data.clientId
					}

					data.required.push('userId', 'locationId') // They're always required but missing from json schema (adding them here to avoid breaking API)

					data.autocompleteFields = [
						{
							name: 'userId',
							resource: 'users',
							query: 'aliases.id',
							display: 'id'
						}
					]

					return data
				},
				modifier: async data => {
					// data processing before sending to API
					data.submitterRef = uuid()
					data.fields = Object.entries(data.fields).map(([k, v]) => {
						if (v.length > 0 && v[0].meta) {
							return {
								tag: k,
								values: v.map(e => e.value),
								meta: v.map(e => [e.meta])
							}
						}

						return { tag: k, values: _.castArray(v) }
					})

					return data
				}
			}
		}

		const initData = async () => {
			// data processing to prefill some fields
			const isDeleted = _.get(this.$route, 'query.isDeleted', false)
			const fsId = _.get(this.$route, 'query.formSubmissionsId', null)
			const formId = _.get(this.$route, 'query.formId', null)

			let data
			try {
				data = JSON.parse(_.get(this.$route, 'query.data', '{}'))
			} catch (e) {
				data = {}
			}

			if (data) {
				config.options.value = data
			}

			if (fsId !== null) {
				const formSubmission = await axiosApiClient({
					url: `/form-submissions/${fsId}?isDeleted=${isDeleted}`
				})
				formSubmission.data.fields = formSubmission.data.fields.reduce(
					(acc, value) => {
						if (value.tag === 'BARCODE') {
							acc[value.tag] = []

							for (let i = 0; i < value.values.length; i++) {
								acc[value.tag].push({
									value: value.values[i],
									meta: {
										clientId:
											formSubmission.data.clientRefs[i]
												.clientId,
										format: 'MANUAL'
									}
								})
							}
						} else {
							acc[value.tag] = value.values
						}

						return acc
					},
					{}
				)

				delete formSubmission.data.tag
				delete formSubmission.data.clientId

				config.options.value = formSubmission.data
			} else if (formId !== null) {
				config.options.value.formId = formId
			} else {
				await this.$router.push({ path: '/form-submissions' })
			}

			_.merge(config.options.value, {
				meta: {
					app: 'backoffice',
					os: 'web',
					submittedVia: 'BACKOFFICE',
					browser: navigator.userAgent,
					ver: VUE_APP_VERSION
				}
			})
		}

		initData()

		return config
	}
}
</script>
