<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'order-tag-keys',
		display: [{ key: 'id' }],
		syncEnabled: false,
		canEdit: false
	}
}

export default {
	name: 'OrderTagKeysList',
	components: { ListPageGen },
	data: () => config
}
</script>
