<script>
import _ from 'lodash'
import { rolePermissionCheck } from '@/permission/rolePermission'
import CommonForm from '@/components/CommonForm.vue'
import TooltipButton from '@/components/TooltipButton.vue'

export default {
	name: 'FormFilter',
	components: { CommonForm, TooltipButton },
	props: {
		permissionModuleName: {
			type: String,
			required: true
		},
		commonButtons: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {}
	},
	async created() {
		this.canCreate = rolePermissionCheck(this.permissionModuleName, 'new')
	},
	methods: {
		locationCreation() {
			this.$router.push({ path: '/locations/new' })
		}
	}
}
</script>

<template>
	<common-form :submit="() => {}" :display-btn="false">
		<template #extra-buttons>
			<div class="action-btn">
				<span>Action:</span>
				<tooltip-button
					v-if="commonButtons.includes('new')"
					:tooltips="['Permission: Create Location']"
					:disable="!canCreate"
					color="primary"
					label="Create Location"
					@click="locationCreation"
				/>
			</div>
		</template>
	</common-form>
</template>

<style lang="scss" scoped>
.action-btn {
	display: inline-table;
	span {
		display: table-cell;
		padding: 0 10px 0 20px;
	}
}
</style>
