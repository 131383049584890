<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import Link from '@/components/Link.vue'
import ListPageGen from '@/components/ListPageGen.vue'

const config = {
	options: {
		resource: 'crossdocks',
		display: [
			{ key: 'id' },
			{ key: 'inboundSubmissionId' },
			{ key: 'outboundSubmissionId' },
			{ key: 'status' },
			{ key: 'createdAt' },
			{ key: 'updatedAt' }
		],
		syncEnabled: false,
		canCreateNew: false,
		canEdit: false,
		canDelete: false,
		modifier: async list =>
			list.map(item => {
				const { inboundSubmissionId, outboundSubmissionId, status } =
					item
				const createLink = value =>
					new Vue({
						...Link,
						data: () => ({
							href: `/#/form-submissions/${value}`,
							value
						})
					})
				const inboundLink = createLink(inboundSubmissionId)
				const outboundLink = createLink(outboundSubmissionId)
				const statusIcons = {
					REVIEWING: '🟡',
					REJECTED: '🔴',
					APPROVED: '🟢'
				}
				const statusDisplay = `${statusIcons[status]} ${status}`
				return {
					...item,
					inboundSubmissionId: inboundLink,
					outboundSubmissionId: outboundLink,
					status: statusDisplay
				}
			})
	}
}

export default {
	name: 'CrossdockList',
	components: { ListPageGen },
	data: () => config
}
</script>
