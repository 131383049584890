<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'pickup-points',
		display: [
			{ key: 'id' },
			{ key: 'clientId' },
			{ key: 'address' },
			{ key: 'createdAt' }
		],
		syncEnabled: true,
		filterModifier: async data => {
			delete data.getAll.properties.id
			return data
		},
		modifier: async data =>
			data.map(r => {
				delete r.address.lat
				delete r.address.lng
				return r
			})
	}
}

export default {
	name: 'PickupPointList',
	components: { ListPageGen },
	data: () => config
}
</script>
