<script>
import EditPageGen from './EditPageGen.vue'
import { Notify } from 'quasar'
import _ from 'lodash'

const config = {
	options: {
		resource: 'deliveries',
		schemaModifier: data => {
			const roles = localStorage.getItem('roles')
			const allowStatusEdit =
				roles.includes('ADM') || roles.includes('OPS')
			if (!allowStatusEdit) {
				delete data.properties.status
			}
			return data
		},
		preSubmitModifier: data => {
			const roles = localStorage.getItem('roles')
			const allowStatusEdit =
				roles.includes('ADM') || roles.includes('OPS')

			if (!_.has(data, 'status') && allowStatusEdit) {
				Notify.create({
					message: 'You cannot patch delivery without valid status',
					type: 'negative',
					position: 'top'
				})
				throw Error('ADM/OPS patch delivery without status')
			}
			return data
		}
	}
}

export default {
	name: 'DeliveriesEdit',
	components: { EditPageGen },
	data: () => config,
	errorCaptured(err) {
		const knownErrors = [
			'The order associated with this delivery has been cancelled, please create a new order instead'
		]
		if (typeof err === 'string' && knownErrors.includes(err)) {
			return false
		}
	}
}
</script>

<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<style scoped>
#edit-page-gen
	>>> .jsonform-error-status
	> .controls
	> ._jsonform-input-delete {
	display: none;
}
</style>
