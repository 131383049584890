<script>
import NewPageGen from './NewPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'

const config = {
	options: {
		resource: 'forms',
		schemaModifier: async data => {
			// set header for translations tabs
			data.properties.translations.items.properties.value.valueInLegend = true
			data.properties.attributes.properties.icon.file = true
			// set default bgColor (match with Courier default dark color):
			data.properties.attributes.properties.bgColor.default = '#222428'

			const response = await axiosApiClient({ url: `/locales?limit=100` })
			const locales = response.data.map(i => i.id)
			data.properties.translations.items.properties.locale.enum = locales

			delete data.properties.fields
			delete data.properties.requiredFields
			delete data.properties.attributes.properties.clientRefFields
			delete data.properties.attributes.properties.crossdock
			data.autocompleteFields = [
				{
					name: 'clientId', // name of the field in jsonschema
					resource: 'clients',
					query: 'id',
					display: 'id' // field from response to be displayed in the suggestion
				}
			]

			// We can't config non-barcode delivery field when create forms,
			// so we remove the checkbox
			delete data.properties.attributes.properties
				.nonBarcodeDeliveryEnabled

			return data
		},
		modifier: async data => {
			if (data.attributes.bgColor === '') {
				delete data.attributes.bgColor
			}
			if (data.attributes.crossdock) {
				data.attributes.crossdock = data.attributes.crossdock[0]
			}
			return data
		}
	}
}

export default {
	name: 'FormsNew',
	components: { NewPageGen },
	data: () => config
}
</script>

<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>
