<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'routes',
		display: [
			{ key: 'id' },
			{ key: 'clientId' },
			{ key: 'pickupPointId' },
			{ key: 'createdAt' }
		],
		syncEnabled: true,
		filterModifier: async data => {
			delete data.getAll.properties.id
			return data
		}
	}
}

export default {
	name: 'RouteList',
	components: { ListPageGen },
	data: () => config
}
</script>
