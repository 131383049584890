<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import S from 'string'
import _ from 'lodash'
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'users',
		display: [
			{ key: 'id' },
			{
				key: 'nameIsBetaIsVerified',
				name: 'Name,\nUnverified(⚠️)',
				width: '20%'
			}, // consolidate name, beta status, and isVerified into a single column
			{ key: 'aliases', width: '285px' },
			{ key: 'clients' },
			{ key: 'roles' },
			{ key: 'createdAt' }
		],
		syncEnabled: false,
		modifier: async data =>
			data.map(r => {
				const roles = r.roles.join()
				const aliases = _.groupBy(r.aliases, 'type')
				const userAliases = []

				// Display alias in this order. PHONE -> EMAIL -> TELEGRAM
				userAliases.push(...(aliases['PHONE'] || []).map(i => i.id))
				userAliases.push(...(aliases['EMAIL'] || []).map(i => i.id))
				userAliases.push(
					`Telegram: ${
						Object.keys(aliases).includes('TELEGRAM') ? '✅' : '❌'
					}`
				)
				r.aliases = userAliases

				const isBeta =
					(r.tags.find(t => t.key === 'LAST_LOGIN_ENV') || {})
						.value === 'beta'
				r.nameIsBetaIsVerified = `${r.info.name}\n${
					r.isVerified ? '' : '⚠️'
				}${isBeta ? ' [beta]' : ''}`

				return Object.assign({}, r, { roles })
			}),
		sortModifier: data =>
			data === 'info' ? 'Name,\nUnverified(⚠️)' : S(data).humanize().s,
		filterModifier: async data => {
			delete data.getAll.properties.id
			data.getByTag.properties.key = data.getByTag.properties['tags.key']
			delete data.getByTag.properties['tags.key']
			data.getByTag.properties['value'] =
				data.getByTag.properties['tags.value']
			delete data.getByTag.properties['tags.value']
			data.getByTag.prefix = ['value']
			data.getByTag.required = ['value']

			data.getByAliasId.properties.aliasId =
				data.getByAliasId.properties['aliases.id']
			data.getByAliasId.properties.aliasType =
				data.getByAliasId.properties['aliases.type']
			delete data.getByAliasId.properties['aliases.id']
			delete data.getByAliasId.properties['aliases.type']
			data.getByAliasId.prefix = ['aliasId']
			data.getByAliasId.required = ['aliasId']

			return data
		},
		applyFilterModifier: async data => {
			if (
				_.includes(window.localStorage.getItem('roles'), 'OPS') &&
				!data['role']
			) {
				data['bypassRoles'] = 'COR'
			}

			data['tags.key'] = data['key']
			data['tags.value'] = data['value']
			delete data['key']
			delete data['value']

			data['aliases.id'] = data['aliasId']
			data['aliases.type'] = data['aliasType']
			delete data['aliasId']
			delete data['aliasType']
			return data
		}
	}
}

export default {
	name: 'UserList',
	components: { ListPageGen },
	data: () => config
}
</script>
