<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'
import { getLocations } from '@/api/locations-api'

const config = {
	options: {
		resource: 'clients',
		schemaModifier: async data => {
			data.items.properties.id.valueInLegend = true
			data.items.properties.translations.items.properties.name.valueInLegend = true
			data.items.properties.translations.items.properties.logo.file = true

			const formtags = ['']
			// GET common forms
			let response = await axiosApiClient({
				url: `/forms?id=COMMON_*&limit=200`
			})
			formtags.push(...response.data.map(i => i.tag))
			data.items.properties.attributes.properties.zapierUrl.items.properties.formTag.enum =
				formtags
			// GET locations
			response = await getLocations()
			const locations = response.data.map(i => i.id)
			data.items.properties.attributes.properties.zapierUrl.items.properties.location.enum =
				locations
			data.items.properties.locations.items.enum = locations

			response = await axiosApiClient({ url: `/locales?limit=100` })
			const locales = response.data.map(i => i.id)
			data.items.properties.translations.items.properties.locale.enum =
				locales

			const tagsResponse = await axiosApiClient({
				url: `/order-tag-keys?limit=100`
			})
			const tags = tagsResponse.data.map(t => t.id)
			if (tags.length > 0) {
				data.items.properties.orderTags.items.properties.tagId.enum =
					tags
			} else {
				delete data.items.properties.orderTags
			}
			const internalActions = [
				'DELETE_FORM_SUBMISSION',
				'CANCEL_REQUEST',
				'EDIT_REQUEST'
			]
			const actions =
				data.items.properties.attributes.properties.zapierUrl.items
					.properties.action.enum
			data.items.properties.attributes.properties.zapierUrl.items.properties.action.enum =
				actions.filter(i => !internalActions.includes(i))
			//	remove this line if need to support bulk
			data = data.items
			return data
		}
	}
}

export default {
	name: 'ClientNew',
	components: { NewPageGen },
	data: () => config
}
</script>
