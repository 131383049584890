<script>
import _ from 'lodash'
import EditPageGen from './EditPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'
import { getLocations } from '@/api/locations-api'

const config = {
	options: {
		resource: 'users',
		schemaModifier: async data => {
			delete data.properties.aliases.items.properties.attributes
			data.properties.aliases.items.properties.id.valueInLegend = true

			if (!_.includes(localStorage.getItem('roles'), 'ADM')) {
				data.hiddenFields = {
					closest: { "option[value='ADM']:selected": 'li' }
				}
			}

			data.properties.roles.items.enum = _.filter(
				data.properties.roles.items.enum,
				role => role !== 'INT'
			)

			if (_.includes(localStorage.getItem('roles'), 'OPS')) {
				data.properties.roles.items.enum = _.without(
					data.properties.roles.items.enum,
					'COR',
					'ADM'
				)
			}

			const response = await axiosApiClient({ url: `/locales?limit=100` })
			const locales = _.map(response.data, 'id')
			_.set(data.properties.info.properties.locale, 'enum', locales)
			const tagsResponse = await axiosApiClient({
				url: `/user-tag-keys?limit=100`
			})
			const tags = _.map(tagsResponse.data, 'id')
			if (tags.length > 0) {
				tags.push('LAST_LOGIN_ENV')
				_.set(data.properties.tags.items.properties.key, 'enum', tags)
			}

			//	Get the location list as the option for location
			const locaResponse = _.get(await getLocations(), 'data', [])
			const locations = ['', ..._.map(locaResponse, 'id')]
			_.set(
				data.properties.courier.properties.location,
				'enum',
				locations
			)

			// Prevent <NO VALUE> option appear in roles dropdown
			_.set(
				data,
				'hiddenFields',
				_.merge(data.hiddenFields, {
					self: ["option[value='<NO VALUE>']"]
				})
			)

			return data
		},
		modifier: async data => {
			data.courier = { driver: data.driverId, location: data.locationId }
			delete data.driverId
			delete data.locationId
			return data
		}
	}
}

export default {
	name: 'UserEdit',
	components: { EditPageGen },
	data() {
		return config
	}
}
</script>
<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>
