<template>
	<div class="config">
		<h1>{{ title }}</h1>
		<p>
			<textarea
				v-model="jsonConfig"
				class="config-text-area"
				rows="20"
				cols="100"
			/>
		</p>
		<button @click="uploadToS3()">Save config</button>
	</div>
</template>

<script>
import { uploadJsonConfigToS3 } from '../api/s3Client'
import configJsonSchemaValidator from '../lib/utils/configJsonSchemaValidator'
import axiosApiClient from '../api/axiosApiClient'
import log from '@/lib/utils/log'

export default {
	name: 'Config',
	data() {
		return {
			title: 'Config UI',
			jsonConfig: '',
			jsonConfigURL:
				`${process.env.VUE_APP_AWS_S3_DOMAIN}/${process.env.VUE_APP_AWS_S3_BUCKET_ALBUM_NAME}` +
				`/llmplus/${process.env.VUE_APP_ENV}/${process.env.VUE_APP_JSON_CONFIG_URL}`
		}
	},
	mounted() {
		const fetchConfig = async () => {
			try {
				const jsonConfig = await axiosApiClient({
					method: 'GET',
					baseURL: this.jsonConfigURL
				})
				this.jsonConfig = JSON.stringify(jsonConfig.data, null, 4)
			} catch (error) {
				log.error(
					'There was an error getting config: ',
					null,
					error.stack
				)
			}
		}
		fetchConfig()
	},
	methods: {
		async uploadToS3() {
			let data = {}
			try {
				data = JSON.parse(this.jsonConfig)
			} catch (error) {
				alert('Invalid JSON!', error.message)
				throw error
			}

			if (!configJsonSchemaValidator(data)) {
				const jsonError = configJsonSchemaValidator.errors.reduce(
					(accumulator, value, index) => {
						const error = accumulator
						let message = `${value.message} `
						switch (value.keyword) {
							case 'additionalProperties':
								message = `${value.params.additionalProperty} `
								if (error.additionalFields) {
									error.additionalFields += `, ${message}`
								} else {
									message = `Should have no additional fields: ${message}`
									error.additionalFields = message
								}
								break
							default:
								if (error.message) {
									error.message += `; ${message}`
								} else {
									error.message = message
								}
						}
						if (
							configJsonSchemaValidator.errors.length ===
							index + 1
						) {
							if (error.additionalFields) {
								error.message += `; ${error.additionalFields}`
							}
						}
						return error
					},
					{}
				)
				alert(`Unexpected config ${jsonError.message.toString()}`)
				throw jsonError.message
			}
			const configName =
				`${process.env.VUE_APP_AWS_S3_BUCKET_ALBUM_NAME}/llmplus/` +
				`${process.env.VUE_APP_ENV}/${process.env.VUE_APP_JSON_CONFIG_URL}`
			try {
				await uploadJsonConfigToS3(configName, this.jsonConfig)
				alert('Config Successfully Uploaded!')
			} catch (error) {
				alert('There was an error uploading config: ', error.message)
			}
		}
	}
}
</script>

<style scoped>
h3 {
	margin: 40px 0 0;
}
.error {
	color: #d8534f;
}

.config-text-area {
	font-size: 12pt;
}
</style>
