<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'summary-status-actions',
		display: [
			'clientId',
			'availableFor',
			'summaryItemActions',
			'createdAt',
			'updatedAt'
		],
		modifier: async (ctx, data) => {
			data.summaryItemActions = data.summaryItemActions.map(i => i.id)
			return data
		}
	}
}

export default {
	name: 'SummaryStatusActionsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
