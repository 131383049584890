<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'summary-item-actions',
		display: [
			{ key: 'id' },
			{ key: 'clientId' },
			{ key: 'action' },
			{ key: 'translations' },
			{ key: 'createdAt' }
		],
		comparisonDisplay: ['id', 'clientId'],
		postThenPatch: true,
		filterModifier: async data => {
			data.autocompleteFields = [
				{
					name: 'clientId.clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				}
			]
			return data
		}
	}
}

export default {
	name: 'SummaryItemActionsList',
	components: { ListPageGen },
	data: () => config
}
</script>
