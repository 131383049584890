<script>
import S from 'string'
import _ from 'lodash'
import { rolePermissionCheck } from '../../permission/rolePermission'
import SideNav from '@/components/SideNav.vue'
import FormSchema from './components/FormSchema.vue'
import { mapState, mapActions } from 'vuex'

/**
 * '9:00' -> ['9', '00'] or '9:00:00' -> ['9', '00', '00']
 *
 * @param {string}timeString
 * @returns {string}
 */
const timeStringTo2Digits = timeString => {
	if (_.isEmpty(timeString)) {
		return timeString
	}
	const timeArray = _.split(timeString, ':')
	timeArray[0] = timeArray[0].length === 1 ? `0${timeArray[0]}` : timeArray[0]
	return _.join(timeArray, ':')
}
export default {
	name: 'LocationEdit',
	components: { SideNav, FormSchema },
	data: () => {
		const config = {
			name: 'locations',
			canSubmit: rolePermissionCheck('locations', 'edit'),
			localeOptions: []
		}
		return config
	},
	computed: {
		...mapState('locations', ['locationInfo', 'formData'])
	},
	async created() {
		const id = this.$route.params.id || ''
		this.title = `${S(this.name).humanize().s}: ${id}`
		await this.getLocaleOptions()
		this.localeOptions = this.formData.localeOptions
		await this.getLocationById(id)
	},
	methods: {
		...mapActions('locations', [
			'getLocationById',
			'getLocaleOptions',
			'updateLocation'
		]),
		async updateLocationFunc(location) {
			const submitData = {
				id: location.id,
				timezone: location.timezone,
				translations: _.reduce(
					location.translations,
					(res, translation) => {
						if (
							!_.isEmpty(translation.locale) &&
							!_.isEmpty(translation.value)
						) {
							res.push(translation)
						}
						return res
					},
					[]
				),
				phone_formats: location.phoneFormats,
				locales: _.reduce(
					location.locales,
					(res, locale) => {
						if (!_.isEmpty(locale.locale)) {
							res.push(locale.locale)
						}
						return res
					},
					[]
				),
				geo: {
					lat: parseFloat(_.get(location.geo, 'lat')),
					lng: parseFloat(_.get(location.geo, 'lng')),
					radius: parseFloat(_.get(location.geo, 'radius'))
				},
				shiftRegistrationCutoffAt: timeStringTo2Digits(
					location.shiftRegistrationCutoffAt
				),
				shiftTimeRanges: _.map(
					location.shiftTimeRanges,
					timeRanges => ({
						startAt: timeStringTo2Digits(timeRanges.startAt),
						endAt: timeStringTo2Digits(timeRanges.endAt)
					})
				),
				shiftDistricts: location.shiftDistricts,
				shiftGenerationEnabled: location.shiftGenerationEnabled
			}

			return this.updateLocation(submitData)
		}
	}
}
</script>

<template>
	<div id="edit-page">
		<side-nav />
		<div id="edit-page-content">
			<h2>
				{{ title }}
			</h2>
			<form-schema
				submit-label="Update"
				:item-data="locationInfo"
				:locale-options="localeOptions"
				:can-submit="canSubmit"
				:submit-func="updateLocationFunc"
			/>
		</div>
	</div>
</template>

<style scoped>
#edit-page {
	width: 100%;
	margin: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
}
#edit-page-content {
	width: 80%;
	position: sticky;
}
</style>
