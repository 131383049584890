<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import moment from 'moment-timezone'
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'api-tokens',
		syncEnabled: false,
		canEdit: true,
		canDelete: false,
		modifier: async data =>
			data.map(r => {
				delete r.hash
				r.issuedAt = moment.unix(r.issuedAt).format('YYYY-MM-DD HH:mm')
				r.expireAt = moment.unix(r.expireAt).format('YYYY-MM-DD HH:mm')
				return r
			}),
		filterModifier: async data => {
			data.autocompleteFields = [
				{
					name: 'getByClientId.clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				},
				{
					name: 'getByUserId.userId',
					resource: 'users',
					query: 'aliases.id',
					display: 'id'
				}
			]
			return data
		}
	}
}

export default {
	name: 'ApiTokenList',
	components: { ListPageGen },
	data: () => config
}
</script>
