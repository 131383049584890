<script>
import DateInput from '@/components/DateInput.vue'
import CommonForm from '@/components/CommonForm.vue'
import Layout from '@/components/Layout.vue'
import { editApiV3Order } from '@/api/orders-api'
import { getLocationTimezone } from '@/api/locations-api'
import _ from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import * as Sentry from '@sentry/vue'

export default {
	name: 'APIOrderEditForm',
	components: { DateInput, CommonForm, Layout },
	props: {
		details: { type: Object, required: true }
	},
	data() {
		return {
			pickupAt: null,
			deliveryBy: null,
			timezone: '',
			Sentry
		}
	},
	created: async function () {
		this.timezone = await getLocationTimezone(this.details.locationId)
		this.pickupAt = moment(this.details.pickupAt)
			.tz(this.timezone)
			.format('YYYY-MM-DD HH:mm')
		this.deliveryBy = moment(this.details.deliveryBy)
			.tz(this.timezone)
			.format('YYYY-MM-DD HH:mm')
	},
	methods: {
		async submit() {
			try {
				await editApiV3Order(
					this.details.orderDisplayId,
					moment(this.pickupAt).utc(),
					moment(this.deliveryBy).utc()
				)
				this.$q.notify({
					message: `Submitted`,
					type: 'positive',
					position: 'top'
				})
				this.$router.push({ path: `/orders/${this.details.id}` })
			} catch (e) {
				if (_.get(e, 'response.status') !== 404) {
					Sentry.captureMessage('Order: Cannot update API order', {
						level: 'error',
						extra: {
							error: {
								orderDisplayId: this.details.orderDisplayId,
								stack: e.stack
							}
						}
					})
				}
				this.$q.notify({
					message: _.get(e, 'response.data.errors[0].detail'),
					type: 'negative',
					position: 'top'
				})
			}
		}
	},
	computed: {
		disableBtn() {
			// initial value to disable button on initial load
			if (
				this.pickupAtErrorMessage === '' &&
				this.deliveryByErrorMessage === ''
			) {
				return true
			}

			if (
				Array.isArray(this.deliveryByErrorMessage) &&
				this.deliveryByErrorMessage.length !== 0
			) {
				return true
			}

			if (
				Array.isArray(this.pickupAtErrorMessage) &&
				this.pickupAtErrorMessage.length !== 0
			) {
				return true
			}
			return false
		},
		pickupAtErrorMessage() {
			const errorMessage = []

			if (this.timezone) {
				const initialPickUp = moment(this.details.pickupAt)
					.tz(this.timezone)
					.format('YYYY-MM-DD HH:mm')

				if (moment(this.pickupAt).isSame(moment(initialPickUp))) {
					return ''
				}
			}

			const newPickupAtTime = moment.utc(this.pickupAt)
			const durationInDays = newPickupAtTime.diff(moment(), 'days')

			if (durationInDays > 30) {
				errorMessage.push('Pickup at should not exceed 30 days')
			}

			if (moment(this.pickupAt).isBefore(moment())) {
				errorMessage.push(
					'Pickup at cannot be earlier than current time'
				)
			}

			return errorMessage
		},
		deliveryByErrorMessage() {
			const errorMessage = []
			if (moment(this.deliveryBy).isBefore(moment(this.pickupAt))) {
				errorMessage.push(
					'Delivery by cannot be earlier than Pickup at'
				)
			}

			const deliveryByTime = moment.utc(this.deliveryBy)
			const pickupTime = moment.utc(this.pickupAt)
			const durationInMinutes = moment(deliveryByTime).diff(
				moment(pickupTime),
				'minutes'
			)

			const is1HourAfter = _.inRange(durationInMinutes, 0, 60)
			if (is1HourAfter) {
				errorMessage.push(
					'Delivery by should not be less than 1 hour after Pickup at'
				)
			}

			if (this.timezone) {
				const initialDeliveryBy = moment(this.details.deliveryBy)
					.tz(this.timezone)
					.format('YYYY-MM-DD HH:mm')

				if (
					moment(this.deliveryBy).isSame(moment(initialDeliveryBy)) &&
					errorMessage.length === 0
				) {
					return ''
				}
			}

			if (moment(this.deliveryBy).isBefore(moment())) {
				errorMessage.push(
					'Delivery by cannot be earlier than current time'
				)
			}

			return errorMessage
		},
		orderId() {
			return _.get(this.details, 'id')
		},
		clientRef() {
			return _.get(this.details, 'clientRefs[0].value')
		},
		timezoneOffset() {
			return moment.tz(this.timezone).format('(UTCZ)')
		}
	}
}
</script>

<template>
	<layout :title="`Orders: ${orderId}`">
		<h5>Client Ref: {{ clientRef }}</h5>
		<common-form
			:submit="submit"
			:display-reset-btn="false"
			layout="vertical"
			submit-label="Submit"
			:disable-btn="disableBtn"
		>
			<div>
				<date-input
					v-model="pickupAt"
					:label="'Pickup at ' + timezoneOffset"
					jquery
				/>
				<div
					id="error-message"
					v-for="(errorMessages, index) in pickupAtErrorMessage || []"
					:key="errorMessages"
				>
					{{ index + 1 }}. {{ errorMessages }}
				</div>
			</div>

			<div>
				<date-input
					v-model="deliveryBy"
					:label="'Delivery by ' + timezoneOffset"
					jquery
				/>
				<div
					id="error-message"
					v-for="(errorMessages, index) in deliveryByErrorMessage ||
					[]"
					:key="errorMessages"
				>
					{{ index + 1 }}. {{ errorMessages }}
				</div>
			</div>
		</common-form>
	</layout>
</template>

<style>
#error-message {
	color: red;
	margin: 5px 0 15px 10px;
}
</style>
