<template>
	<div id="list-page-gen">
		<side-nav />
		<div id="content">
			<h2>
				{{ name }}
			</h2>
			<hr />
			<div class="list-page-filter">
				<div class="filter-groups">
					<json-form-filter
						v-if="filterGroupsReady"
						:key="filterName"
						:schema="filterGroups"
						:value="jsonformFilterValue"
						@filter="filter"
						@updateSort="updateSort"
					/>
				</div>
				<div id="right">
					<div id="new">
						<div style="float: right">
							<router-link
								v-if="infoEditEnabled"
								:to="{ path: `${details}/info-edit` }"
								tag="button"
							>
								Import new order info
							</router-link>
							<router-link
								v-if="exportEnabled"
								:to="{ path: `${details}/export` }"
								tag="button"
							>
								Export
							</router-link>
							<router-link
								v-if="canCreateNew && permissions.new"
								:to="{ path: `${details}/new` }"
								tag="button"
							>
								Create New
							</router-link>
						</div>
					</div>
					<div v-if="sort.length > 0">
						<div id="sort">
							<span>Sort by</span>
							<select v-model="activeSort" @change="sortData()">
								<option
									v-for="option in sort"
									:key="option.key"
									:value="option"
								>
									<span v-if="option.direction === 'asc'">
										{{ option.header }} &darr;
									</span>
									<span
										v-else-if="option.direction === 'desc'"
									>
										{{ option.header }} &uarr;
									</span>
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div id="divTable">
				<div id="divTableBody">
					<div
						v-for="(value, key) in customComponents"
						id="divTableRow"
						:key="key"
					>
						<render-value :value="value" />
					</div>
				</div>
			</div>
			<table id="api-record">
				<thead>
					<th v-if="resourceDeletable" style="width: 2%">
						<input
							v-model="allSelected"
							type="checkbox"
							@change="selectAll"
						/>
					</th>
					<template v-for="m in mapping">
						<th :key="m.header" :width="m.width ? m.width : null">
							{{ m.header }}
						</th>
					</template>
					<th style="width: 10%">-</th>
				</thead>
				<tr v-if="loading">
					<td colspan="100%">
						<md-progress-spinner
							style="
								margin-left: 45%;
								margin-top: 5%;
								margin-bottom: 5%;
							"
							:md-diameter="70"
							:md-stroke="8"
							md-mode="indeterminate"
						/>
					</td>
				</tr>
				<tr v-for="item in data" v-show="!loading" :key="item.id">
					<td v-if="resourceDeletable">
						<input
							v-if="canDelete(resourceDeletable, item)"
							v-model="selectedIds"
							type="checkbox"
							:value="item.id"
							@click="select"
						/>
					</td>
					<td v-for="m in mapping" :key="m.key">
						<div v-if="Array.isArray(item[m.key])">
							<div id="divTable">
								<div id="divTableBody">
									<div
										v-for="(v, k) in item[m.key]"
										id="divTableRow"
										:key="k"
									>
										<div v-if="typeof v === 'object'">
											<div
												v-for="(value, key) in v"
												:key="key"
												style="
													display: flex;
													justify-content: space-between;
													flex-wrap: wrap;
												"
											>
												<render-value
													:value="`${key}:`"
													style="font-weight: bold"
												/>
												<div
													v-if="Array.isArray(value)"
												>
													<div
														id="divTable"
														style="float: right"
													>
														<div id="divTableBody">
															<div
																v-for="(
																	val, key
																) in value"
																id="divTableRow"
																:key="key"
															>
																<render-value
																	:value="val"
																/>
															</div>
														</div>
													</div>
												</div>
												<div v-else>
													<render-value
														:value="value"
													/>
												</div>
											</div>
										</div>
										<div v-else>
											<render-value :value="v" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							v-else-if="
								typeof item[m.key] === 'object' &&
								item[m.key] !== null &&
								!item[m.key]._isVue
							"
						>
							<div
								v-for="(value, key) in item[m.key]"
								:key="key"
								style="display: flex"
							>
								<span style="font-weight: bold">
									{{ key }}:
								</span>
								<render-value :value="value" />
							</div>
						</div>
						<div v-else>
							<render-value :value="item[m.key]" />
						</div>
					</td>
					<td>
						<router-link
							v-if="permissions.detail"
							style="font-size: 23px"
							title="details"
							:to="{
								path: `${details}/${item[id]}`,
								query: !!item.deletedAt
									? { isDeleted: true }
									: undefined
							}"
						>
							🗒️
						</router-link>
						<router-link
							v-if="permissions.edit && canEdit"
							style="font-size: 23px"
							title="edit"
							:to="{
								path: `${details}/edit/${item[id]}`
							}"
						>
							✏️
						</router-link>
						<a
							v-if="
								permissions.delete &&
								resourceDeletable &&
								canDelete(resourceDeletable, item) &&
								selectedIds.length === 0
							"
							style="font-size: 23px"
							title="delete"
							href="javascript:void(0)"
							@click="onDelete([item.id])"
						>
							❌
						</a>
						<a
							v-if="
								permissions.copy &&
								canCopyForNew(item) &&
								selectedIds.length === 0
							"
							style="font-size: 23px"
							title="copy in new"
							href="javascript:void(0)"
							@click="onCopyForNewClick(item)"
						>
							♻️
						</a>
					</td>
				</tr>
			</table>
			<div class="flex bottom">
				<div id="bulkDelete">
					<button
						v-if="selectedIds.length > 0 && name === 'Deliveries'"
						style="margin-left: 0px; margin-right: 10px"
						@click="addDeliveriesSubmissionList"
					>
						Add to Submission List
					</button>
					<button
						v-if="permissions.delete && selectedIds.length > 0"
						style="margin-left: 0px"
						@click="bulkDelete"
					>
						🗑 Bulk Delete
					</button>
					<button
						v-if="selectedIds.length === 0 && syncEnabled"
						style="margin-left: 0px"
						@click="compare"
					>
						Compare to {{ envToSync }}
					</button>
				</div>
				<div id="pagination">
					<div id="page-controls">
						<a
							v-if="currentPage !== 1"
							href="javascript:void(0)"
							@click="paginate(currentPage - 1)"
						>
							prev
						</a>
						<a
							href="javascript:void(0)"
							style="margin-left: 10px"
							@click="paginate(currentPage + 1)"
						>
							next
						</a>
					</div>
				</div>
			</div>

			<deliveries-submission-list
				v-if="
					deliveriesSubmissionList.length > 0 && name === 'Deliveries'
				"
			/>

			<!-- eslint-disable-next-line vue/no-deprecated-v-bind-sync -->
			<md-dialog class="diffDialog" :md-active.sync="showDiff">
				<md-dialog-content>
					<md-progress-spinner
						v-if="showSpinner"
						style="margin-left: 45%; margin-top: 8%"
						:md-diameter="70"
						:md-stroke="8"
						md-mode="indeterminate"
					/>
					<table v-if="diffItems.length > 0" id="comparison-table">
						<thead>
							<th style="width: 2%">
								<input
									v-if="canSelect"
									v-model="allSelectedToSync"
									type="checkbox"
									@change="selectAllToSync"
								/>
							</th>
							<th style="width: 80px" />
							<th v-for="m in comparisonDisplay" :key="m.header">
								{{ m.header }}
							</th>
							<th style="width: 50%" />
						</thead>
						<tr v-for="item in diffItems" :key="item.id">
							<td>
								<input
									v-if="canSelect"
									v-model="selectedItemsToSync"
									type="checkbox"
									:value="item.id"
									@click="selectItemToSync"
								/>
							</td>
							<td>
								<span>
									<md-badge
										v-if="item.syncAction === 'Added'"
										class="md-square"
										md-content="Added"
										style="
											color: #fff;
											background-color: #28a745;
											position: inherit;
										"
									/>
									<md-badge
										v-if="item.syncAction === 'Modified'"
										class="md-square"
										md-content="Modified"
										style="
											color: #fff;
											background-color: #17a2b8;
											position: inherit;
										"
									/>
									<md-badge
										v-if="item.syncAction === 'Deleted'"
										class="md-square"
										md-content="Deleted"
										style="
											color: #fff;
											background-color: #dc3545;
											position: inherit;
										"
									/>
									<md-icon
										v-if="item.syncAction === 'Error'"
										style="color: #dc3545"
									>
										error_outline
									</md-icon>
									<md-icon
										v-if="item.syncAction === 'Ok'"
										style="color: #28a745"
									>
										done
									</md-icon>
								</span>
							</td>
							<td v-for="m in comparisonDisplay" :key="m.key">
								<div v-if="Array.isArray(item[m.key])">
									<div id="divTable">
										<div id="divTableBody">
											<div
												v-for="(v, k) in item[m.key]"
												id="divTableRow"
												:key="k"
											>
												<div
													v-if="typeof v === 'object'"
												>
													<div
														v-for="(
															value, key
														) in v"
														:key="key"
													>
														<render-value
															:value="`${key}:`"
															style="
																float: left;
																font-weight: bold;
															"
														/>
														<render-value
															:value="value"
														/>
													</div>
												</div>
												<div v-else>
													<render-value :value="v" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									v-else-if="
										typeof item[m.key] === 'object' &&
										item[m.key] !== null &&
										!item[m.key]._isVue
									"
								>
									<div
										v-for="(value, key) in item[m.key]"
										:key="key"
									>
										<b style="float: left">{{ key }}:</b>
										<render-value :value="value" />
									</div>
								</div>
								<div v-else>
									<render-value :value="item[m.key]" />
								</div>
							</td>
							<td>
								<div
									v-for="(value, key) in changesDetails[
										item['id']
									]"
									:key="key"
								>
									<div id="divTable">
										<div id="divTableBody">
											<div
												v-if="key.includes('.+')"
												id="divTableRow"
												class="add"
											>
												<span
													id="divTableCell"
													class="changes add-icon"
												>
													＋
												</span>
												<render-value
													:value="`${key.replace(
														/[.][+]/g,
														'.'
													)}:`"
													style="
														float: left;
														font-weight: bold;
													"
												/>
												<render-value :value="value" />
											</div>
											<div
												v-else-if="key.includes('.-')"
												id="divTableRow"
												class="delete"
											>
												<span
													id="divTableCell"
													class="changes delete-icon"
												>
													✕
												</span>
												<render-value
													:value="`${key.replace(
														/[.][-]/g,
														'.'
													)}:`"
													style="
														float: left;
														font-weight: bold;
													"
												/>
												<render-value :value="value" />
											</div>
											<div
												v-else
												id="divTableRow"
												class="modified"
											>
												<span
													id="divTableCell"
													class="changes modified-icon"
												>
													✎
												</span>
												<render-value
													:value="`${key}:`"
													style="
														float: left;
														font-weight: bold;
													"
												/>
												<render-value :value="value" />
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</table>
					<div class="flex bottom">
						<md-progress-bar md-mode="query" />
					</div>
				</md-dialog-content>
				<md-dialog-actions>
					<md-button class="md-primary" @click="closeDiff">
						Close
					</md-button>
				</md-dialog-actions>
			</md-dialog>
			<!-- eslint-disable-next-line vue/no-deprecated-v-bind-sync -->
			<md-dialog
				v-model:md-active="showProgress"
				class="diffDialog"
				md-title="Post created!"
				:md-close-on-esc="false"
				:md-click-outside-to-close="false"
			>
				<md-progress-bar md-mode="determinate" :md-value="progress" />
			</md-dialog>
			<!-- eslint-disable-next-line vue/no-deprecated-v-bind-sync -->
			<md-dialog
				v-model:md-active="showPreview"
				:md-close-on-esc="false"
				:md-click-outside-to-close="false"
			>
				<md-dialog-title>{{ alertTitle }}</md-dialog-title>
				<md-dialog-content>
					{{ alertContent }}
				</md-dialog-content>
				<md-dialog-actions>
					<md-button class="md-primary" @click="reviewSync">
						Review
					</md-button>
				</md-dialog-actions>
			</md-dialog>
			<!-- eslint-disable-next-line vue/no-deprecated-v-bind-sync -->
			<md-dialog-alert
				v-model:md-active="showAlert"
				md-title="No changes found!"
				md-confirm-text="Close"
			/>
		</div>
	</div>
</template>

<script>
import S from 'string'
import _ from 'lodash'
import flatten from 'flat'
import Ajv from 'ajv'
import moment from 'moment'
import jsonwebtoken from 'jsonwebtoken'
import axiosApiClient from '../api/axiosApiClient'
import { rolePermissionCheck } from '../permission/rolePermission'
import RenderValue from './RenderValue.vue'
import SideNav from './SideNav.vue'
import JsonFormFilter from './JsonFormFilter.vue'
import DeliveriesSubmissionList from './DeliveriesSubmissionList.vue'
import { mapState } from 'vuex'
import log from '@/lib/utils/log'

const { VUE_APP_ENV } = process.env

const prodEnvs = {
	pre: {
		envToSync: 'prd',
		urlToSync: 'https://sg-llmplus.lalamove.com'
	},
	prd: {
		envToSync: 'pre',
		urlToSync: 'https://sg-llmplus-pre.lalamove.com'
	}
}

export default {
	name: 'ListPageGen',
	components: {
		RenderValue,
		SideNav,
		JsonFormFilter,
		DeliveriesSubmissionList
	},
	props: ['options'],
	data() {
		const modifier = async data => data
		const filterModifier = async data => data
		const applyFilterModifier = async data => data
		const sortModifier = data => S(data).humanize().s
		const zone_name = moment.tz.guess()
		const timezone = moment.tz(zone_name).format('UTCZ')
		const ctx = {
			data: [],
			id: this.options.id || 'id',
			name: this.options.name || S(this.options.resource).humanize().s,
			details: this.$route.path,
			permissions: {},
			mapping:
				this.options.display === undefined
					? []
					: this.options.display.map(k => {
							let { header } = k
							const { key, width, isDateTime } = k
							header =
								k.name === undefined
									? S(k.key).humanize().s
									: k.name
							header =
								isDateTime ||
								k.key === 'updatedAt' ||
								k.key === 'createdAt'
									? header.concat(' (', timezone, ') ')
									: header
							return { header, key, width }
					  }),
			comparisonDisplay:
				this.options.comparisonDisplay === undefined
					? [{ header: 'Id', key: 'id' }]
					: this.options.comparisonDisplay.map(k => ({
							header: S(k).humanize().s,
							key: k
					  })),
			modifier: this.options.modifier || modifier,
			pageSize: 25,
			currentPage: 1,
			sort: [],
			activeSort: {},
			sortGroups: {},
			sortModifier: this.options.sortModifier || sortModifier,
			filterModifier: this.options.filterModifier || filterModifier,
			applyFilterModifier:
				this.options.applyFilterModifier || applyFilterModifier,
			filters: {},
			filterGroups: {},
			filterGroupsReady: false,
			infoEditEnabled: this.options.infoEditEnabled || false,
			exportEnabled: this.options.exportEnabled || false,
			canCreateNew:
				this.options.canCreateNew !== undefined
					? this.options.canCreateNew
					: true,
			canCopyForNew:
				this.options.canCopyForNew !== undefined
					? this.options.canCopyForNew
					: () => {
							return false
					  },
			onCopyForNew:
				this.options.onCopyForNew !== undefined
					? this.options.onCopyForNew
					: () => {},
			canEdit:
				this.options.canEdit !== undefined
					? this.options.canEdit
					: true,
			resourceDeletable:
				this.options.canDelete !== undefined
					? this.options.canDelete
					: true,
			allSelected: false,
			selectedIds: [],
			syncEnabled:
				Boolean(prodEnvs[VUE_APP_ENV]) &&
				_.get(this.options, 'syncEnabled', true),
			syncPostId: this.options.syncPostId || false,
			envToSync: _.get(prodEnvs, `${VUE_APP_ENV}.envToSync`, ''),
			urlToSync: _.get(prodEnvs, `${VUE_APP_ENV}.urlToSync`, ''),
			syncTargetTokenKey: '',
			crossEnvToken: '',
			changesDetails: {},
			selectedItemsToSync: [],
			allSelectedToSync: false,
			diffItems: [],
			showDiff: false,
			showProgress: false,
			progress: 0,
			showPreview: false,
			showAlert: false,
			alertTitle: '',
			alertContent: '',
			canSelect: false,
			postThenPatch: this.options.postThenPatch || false,
			showSpinner: false,
			customComponents: this.options.customComponents || [],
			loading: true,
			filterName: undefined,
			jsonformFilterValue: null
		}

		const initData = async () => {
			const { name } = this.$route.query
			let { filters } = this.$route.query
			if (filters) {
				const filterObj = JSON.parse(filters)
				ctx.filters = filterObj
				ctx.filterName = name
				ctx.jsonformFilterValue = { [name]: { ...filterObj } }
				const { limit, offset } = filterObj
				if (offset) {
					ctx.currentPage = offset / limit + 1
				}
			}

			filters = await ctx.applyFilterModifier(_.cloneDeep(ctx.filters))
			const params = { limit: ctx.pageSize, ...filters }
			let response = await axiosApiClient({
				url: this.options.resource,
				params
			})
			const data = await ctx.modifier(response.data)
			ctx.data = data
			if (ctx.mapping.length === 0) {
				if (ctx.data.length > 0) {
					const keys = Object.keys(ctx.data[0])
					ctx.mapping = keys.map(k => {
						let header = S(k).humanize().s
						header =
							k === 'updatedAt' || k === 'createdAt'
								? header.concat(' (', timezone, ') ')
								: header
						return { header, key: k }
					})
				}
			}

			ctx.syncTargetTokenKey = `TOKEN:${ctx.urlToSync}`
			if (ctx.syncEnabled) {
				// handles no token and expired token
				ctx.crossEnvToken = localStorage.getItem(ctx.syncTargetTokenKey)
				const parsedToken =
					jsonwebtoken.decode(ctx.crossEnvToken, {
						complete: true
					}) || {}
				const exp = (parsedToken.payload || {}).exp || 1 // 1 is a valid timestamp (01/01/1970 @ 12:00am (UTC))
				if (moment.utc().unix() > exp) {
					await this.authenticate()
				}
			}

			ctx.permissions = {
				detail: rolePermissionCheck(this.options.resource, 'detail'),
				new: rolePermissionCheck(this.options.resource, 'new'),
				edit: rolePermissionCheck(this.options.resource, 'edit'),
				copy: rolePermissionCheck(this.options.resource, 'copy'),
				delete: rolePermissionCheck(this.options.resource, 'delete')
			}

			try {
				response = await axiosApiClient({
					url: `${this.options.resource}/schemas/get`
				})
				if (!response.data.query) {
					throw Error('Empty response')
				}
				const { definitions } = response.data.query
				// filters
				if (response.data.query.oneOf) {
					response.data.query.oneOf.forEach(filter => {
						const name = filter.$ref.replace(
							/^#\/definitions\//,
							''
						)
						const schema = definitions[name]
						// schema clean up
						delete schema.properties.limit
						delete schema.properties.offset
						schema.notitle = true
						ctx.filterGroups[name] = schema
					})
				}

				ctx.filterGroups = await ctx.filterModifier(ctx.filterGroups)
				const filters = Object.keys(ctx.filterGroups)
				if (filters.length === 0) {
					return
				}
				filters.forEach(i => {
					if (i === 'autocompleteFields') {
						return
					}
					const schema = ctx.filterGroups[i]
					const { sort } = schema.properties
					if (sort) {
						const options = sort.enum.map(k => ({
							header: ctx.sortModifier(k.split('-')[1]),
							key: k,
							direction: k.split('-')[0]
						}))
						const defaultSort = (schema.applyDefault || {}).sort
						ctx.sortGroups[i] = { options, default: defaultSort }
						delete ctx.filterGroups[i].properties.sort
					}
				})
				ctx.sort = ctx.sortGroups[filters[0]].options
				const defaultSort = ctx.sortGroups[filters[0]].default
				ctx.activeSort = ctx.sort.find(s => s.key === defaultSort)
				ctx.filterGroupsReady = true
			} catch (e) {
				log.error(
					'Error on list page initData',
					{ page: ctx.name },
					e.stack
				)
			} finally {
				ctx.loading = false
			}
		}
		initData()
		return ctx
	},
	computed: {
		...mapState({
			deliveriesSubmissionCount: state =>
				state.deliveriesSubmissionList.count,
			deliveriesSubmissionList: state =>
				state.deliveriesSubmissionList.list
		})
	},
	methods: {
		async paginate(i) {
			this.currentPage = i
			const offset = (i - 1) * this.pageSize
			const params = { limit: this.pageSize, offset }
			this.getData(params)
		},
		async sortData() {
			this.currentPage = 1
			this.getData()
		},
		async filter(name, filters = {}) {
			this.jsonformFilterValue = { [name]: { ...filters } }
			this.filters = filters
			this.filterName = name
			this.currentPage = 1
			const params = { limit: this.pageSize }
			if (Object.keys(filters).length === 0) {
				this.jsonformFilterValue = null
			}
			this.getData(params)
		},
		async getData(params) {
			const sort = this.activeSort.key
			const paramsObj = _.cloneDeep(params)
			const filters = await this.applyFilterModifier(
				_.cloneDeep(this.filters)
			)
			params = Object.assign({}, filters, paramsObj, { sort })
			const response = await axiosApiClient({
				url: `${this.options.resource}`,
				params
			})
			const data = await this.modifier(response.data)
			this.data = data

			const urlQueryFilter = { ...this.filters, ...paramsObj }

			this.$router.push({
				path: this.$route.path,
				query: {
					name: this.filterName,
					filters: JSON.stringify(urlQueryFilter)
				}
			})
		},
		async updateSort(sortGroupName) {
			this.activeSort = {}
			if (this.sortGroups[sortGroupName]) {
				const defaultSort = (this.sortGroups[sortGroupName] || {})
					.default
				this.sort = (this.sortGroups[sortGroupName] || {}).options
				this.activeSort = this.sort.find(s => s.key === defaultSort)
			}
		},
		async onCopyForNewClick(item) {
			await this.onCopyForNew(this.$router, item)
		},
		async onDelete(ids) {
			if (
				!window.confirm(
					`Are you sure to delete entry(ies) with ID(s) '${ids}'?`
				)
			) {
				return
			}
			try {
				for (const id of ids) {
					await axiosApiClient({
						method: 'DELETE',
						url: `${this.options.resource}/${id}`
					})
					this.data = this.data.filter(i => i.id !== id)
				}
			} catch (error) {
				log.error(
					'Error on deleting entry(ies)',
					{ category: 'API' },
					error.stack
				)
				alert(error)
			}
		},
		async selectAll() {
			this.selectedIds = []
			if (this.allSelected) {
				const ids = this.data
					.filter(e => this.canDelete(true, e))
					.map(i => i.id)
				this.selectedIds = ids
			}
		},
		async select() {
			this.allSelected = false
		},
		async bulkDelete() {
			await this.onDelete(this.selectedIds)
			this.clearSelectedIds()
			this.allSelected = false
		},
		async selectAllToSync() {
			this.selectedItemsToSync = []
			if (this.allSelectedToSync) {
				const ids = [...this.diffItems.map(i => i.id)]
				this.selectedItemsToSync = ids
			}
		},
		async selectItemToSync() {
			this.allSelectedToSync = false
		},
		async compare() {
			this.showDiff = true
			this.showSpinner = true
			this.canSelect = true
			this.diffItems = []
			this.changesDetails = {}
			const objectDiff = (object, base) => {
				function changes(object, base) {
					const accumulator = {}
					Object.keys(base).forEach(key => {
						if (object[key] === undefined) {
							accumulator[`-${key}`] = base[key]
						}
					})
					return _.transform(
						object,
						(accumulator, value, key) => {
							if (base[key] === undefined) {
								accumulator[`+${key}`] = value
							} else if (!_.isEqual(value, base[key])) {
								accumulator[key] =
									_.isObject(value) && _.isObject(base[key])
										? changes(value, base[key])
										: value
							}
						},
						accumulator
					)
				}
				return changes(object, base)
			}
			try {
				// get data from target env
				let response = await axiosApiClient({
					url: `${this.urlToSync}/${this.options.resource}?limit=200`,
					headers: { Authorization: `Bearer ${this.crossEnvToken}` }
				})
				const data = _.groupBy(response.data, 'id')
				// get data from source env
				response = await axiosApiClient({
					url: `${this.options.resource}?limit=200`
				})
				const sourceData = response.data
				// get patch schema of target env
				response = await axiosApiClient({
					url: `${this.urlToSync}/${this.options.resource}/schemas/patch`
				})
				const patchSchema = response.data.body || response.data
				const ajv = new Ajv({
					removeAdditional: true,
					useDefaults: 'empty'
				})
				const patchRemoveAdditional = ajv.compile(patchSchema)

				sourceData.forEach(i => {
					let item = (data[i.id] || [])[0]
					if (!item) {
						this.diffItems.push(
							Object.assign({ syncAction: 'Added' }, i)
						)
						return
					}
					delete data[i.id]
					const updateObject = Object.assign({}, i)

					// strip subresource object
					i = _.omitBy(i, _.isNil) // we only want to remove null and undefined
					item = _.omitBy(item, _.isNil) // we only want to remove null and undefined
					patchRemoveAdditional(i)
					patchRemoveAdditional(item)

					const isEqual = _.isEqual(i, item)
					if (!isEqual) {
						this.changesDetails[updateObject.id] = flatten(
							objectDiff(i, item)
						)
						this.diffItems.push(
							Object.assign(
								{ syncAction: 'Modified' },
								updateObject
							)
						)
					}
				})
				this.diffItems.push(
					..._.flatMap(data).map(i => {
						i.syncAction = 'Deleted'
						return i
					})
				)
				this.diffItems.sort((a, b) =>
					a.syncAction > b.syncAction ? 1 : -1
				)
			} catch (error) {
				log.error('Error on compare', null, error.stack)
				alert(error)
			}
			this.showSpinner = false
			if (this.diffItems.length === 0) {
				this.showDiff = false
				this.showAlert = true
			}
		},
		async post(data) {
			const headers = { Authorization: `Bearer ${this.crossEnvToken}` }
			const method = 'POST'
			const url = `${this.urlToSync}/${this.options.resource}`
			let response
			try {
				response = await axiosApiClient({ method, url, data, headers })
			} catch (error) {
				log.error(
					'Error on POST',
					{ category: 'API', url },
					error.stack
				)
				const status = error.response.status
				const data = error.response.data
				response = Object.assign({}, { status, data })
			}
			return response
		},
		async patch(id, data) {
			const headers = { Authorization: `Bearer ${this.crossEnvToken}` }
			const method = 'PATCH'
			const url = `${this.urlToSync}/${this.options.resource}/${id}`
			let response
			try {
				response = await axiosApiClient({ method, url, data, headers })
			} catch (error) {
				log.error(
					'Error on PATCH',
					{ category: 'API', url },
					error.stack
				)
				const status = error.response.status
				const data = error.response.data
				response = Object.assign({}, { status, data })
			}
			return response
		},
		async delete(id) {
			const headers = { Authorization: `Bearer ${this.crossEnvToken}` }
			const method = 'DELETE'
			const url = `${this.urlToSync}/${this.options.resource}/${id}`
			let response
			try {
				response = await axiosApiClient({ method, url, headers })
			} catch (error) {
				log.error(
					'Error on DELETE',
					{ category: 'API', url },
					error.stack
				)
				const status = error.response.status
				const data = error.response.data
				response = Object.assign({}, { status, data })
			}
			return response
		},
		async closeDiff() {
			this.showDiff = false
			this.diffItems = []
		},
		async reviewSync() {
			this.showDiff = true
			this.showPreview = false
			this.canSelect = false
		},
		async authenticate() {
			try {
				const gToken = localStorage.getItem('gToken')
				const response = await axiosApiClient({
					method: 'POST',
					url: `${this.urlToSync}/login/google`,
					data: { token: gToken }
				})
				const { token } = response.data
				this.crossEnvToken = token
				localStorage.setItem(this.syncTargetTokenKey, token)
			} catch (e) {
				this.syncEnabled = false
				log.error(
					'Error on getting token for environment sync',
					null,
					e.stack
				)
			}
		},
		async addDeliveriesSubmissionList() {
			const newItems = []
			this.selectedIds.forEach(id => {
				const val = this.data.find(i => i.id === id)
				newItems.push(_.cloneDeep(_.omit(val, ['formSubmit'])))
			})
			this.$store.dispatch('deliveriesSubmissionList/addToList', newItems)
			this.selectedIds = []
			this.allSelected = false
		},
		canDelete(isResourceDeletable, item) {
			return (
				isResourceDeletable &&
				(item.deletedAt === undefined || item.deletedAt === null)
			)
		},
		clearSelectedIds() {
			this.selectedIds = []
		}
	}
}
</script>

<style lang="scss">
#list-page-gen {
	width: 98%;
	margin: auto;
	display: flex;
	position: absolute;

	hr {
		width: 100%;
	}

	#content {
		width: 100%;
	}

	.list-page-filter {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;

		.filter-groups {
			width: 100%;
		}

		button {
			margin-left: 10px;
		}

		#new {
			display: block;
			width: 100%;
		}
	}

	#right {
		display: flex;
		flex-direction: column;
		width: 20%;
		justify-content: space-between;
		margin-bottom: 25px;

		#pagination,
		#bulkDelete,
		#sync {
			float: right;
			color: black;
			text-decoration: none;
			width: 50%;
		}

		#pagination {
			#page-controls {
				float: right;
			}
		}

		#sort {
			float: right;
		}
	}

	#api-record,
	#comparison-table,
	#generic-table {
		border: 1px solid #ddd;
		border-collapse: collapse;
		margin: auto;
		width: 100%;

		td,
		th {
			border-bottom: 1px solid #ddd;
			text-align: left;
			padding: 2px 6px 2px 2px;
			word-break: break-word;
		}

		td {
			word-wrap: break-word;
			max-width: 125px;
		}

		th {
			top: 0;
			position: sticky;
			background-color: #fff;
			white-space: pre-wrap;
		}

		tr:nth-child(even) {
			background-color: #eee;
		}
	}

	#comparison-table {
		margin-top: 20px;
	}
}

.filter-group-name {
	font-weight: bold;
	font-size: 18px;
}
.flex {
	display: flex;
}

.bottom {
	padding-top: 20px;
}

.diffDialog {
	& > .md-dialog-container {
		width: 100%;
	}

	.delete-icon {
		color: #dc3545;
		font-weight: bold;
		font-size: 15px;
	}

	.modified-icon {
		color: #17a2b8;
		font-weight: bold;
		font-size: 15px;
	}

	.changes {
		width: 30px;
	}

	.add-icon {
		color: #28a745;
		font-weight: bold;
		font-size: 15px;
	}
}

.md-dialog-content:first-child {
	padding-top: 0 !important;
}
</style>
