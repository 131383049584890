<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'

const config = {
	options: {
		resource: 'locales'
	}
}

export default {
	name: 'LocaleNew',
	components: { NewPageGen },
	data: () => config
}
</script>
