import Ajv from 'ajv'

const schema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		data: {
			type: 'object',
			additionalProperties: true,
			properties: {
				courierAppVersion: {
					type: 'string'
				}
			},
			required: ['courierAppVersion']
		}
	},
	required: ['data']
}

const ajv = new Ajv({ allErrors: true })
const configJsonSchemaValidator = ajv.compile(schema)
export default configJsonSchemaValidator
