<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import Link from '@/components/Link.vue'
import DetailsPageGen from '@/components/DetailsPageGen.vue'

const config = {
	options: {
		resource: 'crossdocks',
		canEdit: false,
		canDelete: false,
		modifier: async (_, data) => {
			const { inboundSubmissionId, outboundSubmissionId, status } = data
			const createLink = value =>
				new Vue({
					...Link,
					data: () => ({
						href: `/#/form-submissions/${value}`,
						value
					})
				})
			const inboundLink = createLink(inboundSubmissionId)
			const outboundLink = createLink(outboundSubmissionId)
			const statusIcons = {
				REVIEWING: '🟡',
				REJECTED: '🔴',
				APPROVED: '🟢'
			}
			const statusDisplay = `${statusIcons[status]} ${status}`
			return {
				...data,
				inboundSubmissionId: inboundLink,
				outboundSubmissionId: outboundLink,
				status: statusDisplay
			}
		}
	}
}

export default {
	name: 'CrossdockDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
