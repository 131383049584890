<template>
	<div class="hello">
		<img alt="Lalamove logo" src="../assets/logo.png" />
		<h1>Welcome to Lalamove+ Back Office Panel</h1>
		<h3>Ping Response</h3>

		<div v-if="error" class="error">
			<b>Error:</b>
			{{ error }}
		</div>
		<div v-else>
			<div v-if="loading">Loading...</div>
			<div v-else>
				<p>
					<b>Ping:</b>
					{{ ping }}
				</p>
				<p>
					<b>Schema Version:</b>
					{{ schemaVersion }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import axiosApiClient from '../api/axiosApiClient'

export default {
	name: 'Hello',
	props: {
		title: String
	},
	data() {
		return {
			error: '',
			loading: false,
			ping: '',
			schemaVersion: ''
		}
	},
	mounted() {
		const fetchPing = async () => {
			this.loading = true
			try {
				const { data } = await axiosApiClient.get('ping')
				this.error = ''
				this.ping = data.ping
				this.schemaVersion = data.schemaVersion
			} catch (error) {
				this.error = error.message
				this.ping = ''
				this.schemaVersion = ''
			}
			this.loading = false
		}
		fetchPing()
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	margin: 40px 0 0;
}
.error {
	color: #d8534f;
}
</style>
