<template>
	<div class="json-form-filter">
		<form id="json-form-fields-filter" class="filter" />
	</div>
</template>
<script>
/* global $, Bloodhound */

import S from 'string'

const dateKeys = []
const prefix = {}

const dateValToUTC = function (values) {
	dateKeys.forEach(k => {
		if (!values[k]) {
			delete values[k]
			return
		}
		const utcDate = new Date(values[k])
		values[k] = utcDate.toISOString()
	})
	return values
}

function setTitleAndRequireFields(obj, required) {
	const keys = Object.keys(obj)
	if (keys.includes('properties')) {
		let requiredFields = []
		if (keys.includes('required')) {
			requiredFields = obj.required
			delete obj.required
		}
		return setTitleAndRequireFields(obj.properties, requiredFields)
	}
	keys.forEach(k => {
		if (!Array.isArray(obj[k]) && typeof obj[k] === 'object') {
			if (
				obj[k].enum &&
				!(required || []).includes(k) &&
				!obj[k].enum.includes('')
			) {
				obj[k].enum.unshift('')
			}
			const { format } = obj[k]
			if (format !== undefined && format.startsWith('date')) {
				obj[k].type = 'datetime'
				dateKeys.push(k)
			}
			obj[k].title = obj[k].notitle
				? ''
				: obj[k].title || S(k).humanize().s
			if (required !== undefined && Array.isArray(required)) {
				obj[k].required =
					obj[k].required !== undefined
						? obj[k].required
						: required.includes(k)
			}
			setTitleAndRequireFields(obj[k])
		}
	})
	return obj
}

function generateForm(schema, vueInstance) {
	const titleMap = {}
	const selection = []
	for (const property in schema) {
		titleMap[property] = S(property).humanize().s
		selection.push(property)
		const filter = schema[property]
		if (filter.prefix) {
			prefix[property] = filter.prefix
		}
	}
	const form = [
		{
			type: 'selectfieldset',
			key: 'choice',
			onChange(evt) {
				const sortGroupName = $('select.nav').val()
				vueInstance.updateSort(sortGroupName)
				setImmediate(function () {
					$('input:text:focusable:first').focus()
				})
			},
			title: 'Filter By',
			titleMap,
			items: selection
		},
		{
			type: 'submit',
			htmlClass: 'filterbtn',
			title: 'Apply'
		},
		{
			type: 'button',
			htmlClass: 'filterbtn',
			title: 'Reset',
			onClick(evt) {
				$('#json-form-fields-filter').html('')
				vueInstance.updateSort('getAll')
				vueInstance.filter(undefined, {})
				vueInstance.generateFilters()
				evt.preventDefault()
			}
		}
	]
	return form
}

export default {
	name: 'JsonFormFilter',
	props: ['schema', 'value'],
	mounted() {
		this.generateFilters()
	},
	methods: {
		filter(name, filters) {
			this.$emit('filter', name, filters)
		},
		updateSort(sortGroupName) {
			this.$emit('updateSort', sortGroupName)
		},
		generateFilters() {
			const value = this.value
			let schema = Object.assign(this.schema)
			const { autocompleteFields } = schema
			delete schema.autocompleteFields
			delete schema.disabledFields
			schema = setTitleAndRequireFields(schema)
			const keys = Object.keys(schema)
			const vueInstance = this
			const form = generateForm(schema, vueInstance)
			const choice = { type: 'string', enum: keys }
			schema = Object.assign({}, schema, { choice })
			$('#json-form-fields-filter').jsonForm({
				schema,
				form,
				value,
				onSubmitValid(values) {
					const filter = values.choice
					values = values[filter]
					if (values) {
						values = dateValToUTC(values)
						Object.keys(values).forEach(i => {
							if (values[i] === '') {
								delete values[i]
							}
						})
						if (prefix[filter]) {
							const prefixKey = prefix[filter]
							prefixKey.forEach(k => {
								values[k] = values[k]
									? `${values[k]}*`
									: values[k]
							})
						}
					}
					vueInstance.filter(filter, values)
				}
			})

			$('input[type="datetime"]').datetimepicker({
				sideBySide: true
			})

			const token = localStorage.getItem('token')
			if (autocompleteFields) {
				autocompleteFields.forEach(field => {
					const { name, resource, query, display } = field
					const data = new Bloodhound({
						datumTokenizer: Bloodhound.tokenizers.whitespace,
						queryTokenizer: Bloodhound.tokenizers.whitespace,
						remote: {
							url: `${process.env.VUE_APP_API_URL}/${resource}?${query}=%QUERY*`,
							wildcard: '%QUERY',
							prepare: function (query, settings) {
								settings.url = settings.url.replace(
									'%QUERY',
									encodeURIComponent(query)
								)
								settings.headers = {
									Authorization: `Bearer ${token}`
								}
								return settings
							}
						}
					})
					$(`input[name='${name}']`).attr('autocomplete', 'off')
					$(`input[name='${name}']`).typeahead(
						{
							hint: false,
							highlight: true,
							minLength: 3,
							classNames: {
								menu: 'typeahead-menu'
							}
						},
						{
							display: display,
							source: data,
							limit: 5
						}
					)
				})
			}
		}
	}
}
</script>

<style lang="scss">
.jsonform-required > label:after {
	content: '*';
	color: red;
}
#json-form-fields-filter {
	.form-group {
		margin-bottom: 10px;
		margin-right: 15px;
		float: left;
	}

	fieldset.tab-container {
		padding: 0;
	}
}
.tabbable > .form-group {
	width: 100%;
}
.filterbtn {
	float: left;
	margin-bottom: 20px;
}
.filter {
	display: inline-block;
	position: relative;
	width: 100%;
}
.tt-query,
.tt-hint {
	width: 396px;
	height: 30px;
	padding: 8px 12px;
	font-size: 24px;
	line-height: 30px;
	border: 2px solid #ccc;
	border-radius: 8px;
	outline: none;
}
.tt-query {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
	color: #fff;
}
.tt-menu {
	width: 193px;
	margin-top: 12px;
	padding: 8px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tt-suggestion {
	cursor: pointer;
	padding: 3px 20px;
	font-size: 18px;
	line-height: 24px;
}
.tt-suggestion.tt-is-under-cursor {
	color: #fff;
	background-color: #0097cf;
}
.tt-suggestion p {
	margin: 0;
}
</style>
