<script>
import S from 'string'
import _ from 'lodash'
import { rolePermissionCheck } from '../../permission/rolePermission'
import SideNav from '@/components/SideNav.vue'
import FormSchema from './components/FormSchema.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'LocationNew',
	components: { SideNav, FormSchema },
	data: () => {
		const config = {
			name: 'locations',
			canSubmit: rolePermissionCheck('locations', 'new'),
			localeOptions: []
		}
		return config
	},
	computed: {
		...mapState('locations', ['locationInfo', 'formData'])
	},
	async created() {
		const id = this.$route.params.id || ''
		this.title = `${S(this.name).humanize().s}: ${id}`
		await this.getLocaleOptions()
		this.localeOptions = this.formData.localeOptions
		await this.getAvailableLocationIds()
	},
	methods: {
		...mapActions('locations', [
			'getLocationById',
			'getLocaleOptions',
			'getAvailableLocationIds',
			'getLocationInfoByCityId',
			'createLocation'
		]),
		async validate(locationCode) {
			const matchResult = _.find(this.formData.availableLocationIdList, {
				locationCode
			})

			if (_.isEmpty(matchResult)) {
				alert(
					'The Location code already used / not available in city list'
				)
			} else if (
				!_.isEmpty(matchResult) &&
				_.isEmpty(await this.getLocationInfoByCityId(matchResult))
			) {
				alert(
					'The Location code not available because missing the country info'
				)
			} else {
				return true
			}
		},
		async createLocationFunc(location) {
			const submitData = {
				id: location.id,
				timezone: location.timezone,
				translations: _.reduce(
					location.translations,
					(res, translation) => {
						if (
							!_.isEmpty(translation.locale) &&
							!_.isEmpty(translation.value)
						) {
							res.push(translation)
						}
						return res
					},
					[]
				),
				phone_formats: location.phoneFormats,
				locales: _.reduce(
					location.locales,
					(res, locale) => {
						if (!_.isEmpty(locale.locale)) {
							res.push(locale.locale)
						}
						return res
					},
					[]
				),
				geo: {
					lat: parseFloat(_.get(location.geo, 'lat')),
					lng: parseFloat(_.get(location.geo, 'lng')),
					radius: parseFloat(_.get(location.geo, 'radius'))
				},
				shiftRegistrationCutoffAt: location.shiftRegistrationCutoffAt,
				shiftTimeRanges: location.shiftTimeRanges,
				shiftDistricts: location.shiftDistricts,
				shiftGenerationEnabled: location.shiftGenerationEnabled
			}

			return this.createLocation(submitData)
		}
	}
}
</script>

<template>
	<div id="new-page">
		<side-nav />
		<div id="new-page-content">
			<h2>
				{{ title }}
			</h2>
			<form-schema
				submit-label="Create"
				:item-data="locationInfo"
				:locale-options="localeOptions"
				:can-submit="canSubmit"
				:submit-func="createLocationFunc"
				:validate-func="validate"
			/>
		</div>
	</div>
</template>

<style scoped>
#new-page {
	width: 100%;
	margin: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
}
#new-page-content {
	width: 80%;
	position: sticky;
}
</style>
