<template>
	<div id="link">
		<a :href="href" target="_blank">{{ value }}</a>
	</div>
</template>

<script>
export default {
	name: 'Link'
}
</script>
