import axiosApiClient from './axiosApiClient'
import log from '../lib/utils/log'

export const uploadJsonConfigToS3 = async (key, body) => {
	try {
		const { data } = await axiosApiClient.post('upload/json', {
			key,
			body
		})
		return data
	} catch (err) {
		log.error('Failed to upload config to S3', { err })
	}
}

export const uploadImageToS3 = async (key, body) => {
	try {
		const { data } = await axiosApiClient.post('upload/image', {
			key,
			body
		})
		return data
	} catch (err) {
		log.error('Failed to upload image to S3', { err })
	}
}
