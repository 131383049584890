<template>
	<div id="webhook-retry">
		<span>{{ status }}</span>
		<button v-if="status === 'NOT_DONE'" @click="retry">Retry</button>
	</div>
</template>

<script>
import axiosApiClient from '../api/axiosApiClient'
import log from '@/lib/utils/log'

export default {
	name: 'WebhookRetry',
	methods: {
		async retry() {
			try {
				const response = await axiosApiClient({
					method: 'PATCH',
					url: `webhooks/${this.id}`,
					data: { status: 'DONE' }
				})
				this.status = response.data.status
				alert('Webhook successfully retried!')
			} catch (e) {
				log.error('WebhookRetry', { category: 'API' }, e.stack)
				alert(e)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
#webhook-retry {
	button {
		margin-left: 10px;
	}
}
</style>
