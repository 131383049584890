<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'
const display = ['id', 'createdAt', 'updatedAt']

const config = {
	options: {
		resource: 'order-tag-keys',
		display,
		canEdit: false
	}
}

export default {
	name: 'OrderTagKeysDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
