<script>
import DetailsPageGen from '@/components/DetailsPageGen.vue'

const config = {
	options: {
		resource: 'audit-logs',
		canEdit: false,
		canDelete: false
	}
}

export default {
	name: 'AuditLogDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>

<template>
	<details-page-gen :options="options" />
</template>
