<template>
	<div id="new-page-gen">
		<side-nav />
		<div v-if="ready" id="content">
			<h2>
				{{ name }}
			</h2>
			<json-form
				:value="value"
				:schema="schema"
				:modifier="modifier"
				:url="url"
				:method="method"
				:navigate-to="navigateTo"
				:submit-permission="newPermissions"
				:on-initialized="onFormInitialized"
			/>
		</div>
	</div>
</template>

<script>
import S from 'string'
import axiosApiClient from '../api/axiosApiClient'
import { rolePermissionCheck } from '../permission/rolePermission'
import JsonForm from './JsonForm.vue'
import SideNav from './SideNav.vue'

export default {
	components: { JsonForm, SideNav },
	props: {
		options: { type: Object, required: true },
		onFormInitialized: { type: Function, default: () => {} }
	},
	data() {
		const ctx = {
			id: this.options.id || 'id',
			name: this.options.name || S(this.options.resource).humanize().s,
			modifier: this.options.modifier
				? data => this.options.modifier(data)
				: async data => data,
			schema: this.options.schema,
			schemaModifier: this.options.schemaModifier || (async data => data),
			url: this.options.resource,
			newPermissions: rolePermissionCheck(this.options.resource, 'new'),
			method: 'POST',
			ready: false,
			navigateTo: this.options.navigateTo,
			value: this.options.value
		}
		const initData = async () => {
			const url = this.options.resource
			if (ctx.schema === undefined) {
				const response = await axiosApiClient({
					url: `${url}/schemas/post`
				})
				ctx.schema = await ctx.schemaModifier(
					response.data.body || response.data,
					this.options
				)
			}
			ctx.ready = true
		}
		initData()
		return ctx
	}
}
</script>

<style>
#new-page-gen {
	width: 98%;
	margin: auto;
	display: flex;
	position: absolute;
}
#content {
	width: 100%;
}
</style>
