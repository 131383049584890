<script>
import _ from 'lodash'
import NewPageGen from './NewPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'
import { getLocations } from '@/api/locations-api'

const config = {
	options: {
		resource: 'users',
		schemaModifier: async data => {
			data.items.properties.isVerified.default = true

			// Remove the COR creation in user page
			data.items.properties.roles.items.enum = _.filter(
				data.items.properties.roles.items.enum,
				role => role !== 'INT'
			)

			// TODO: remove this handling and only allow to create by new Courier creation
			if (!_.includes(localStorage.getItem('roles'), 'ADM')) {
				data.items.properties.roles.items.enum = _.filter(
					data.items.properties.roles.items.enum,
					role => role !== 'COR' && role !== 'ADM'
				)
			}

			delete data.items.properties.aliases.items.properties.attributes

			const response = await axiosApiClient({ url: `/locales?limit=100` })
			const locales = response.data.map(i => i.id)
			data.items.properties.info.properties.locale.enum = locales

			const tagsResponse = await axiosApiClient({
				url: `/user-tag-keys?limit=100`
			})
			const tags = tagsResponse.data.map(i => i.id)
			if (tags.length > 0) {
				data.items.properties.tags.items.properties.key.enum = tags
			}

			//	Get the location list as the option for location
			const locaResponse = await getLocations()
			const locations = ['', ...locaResponse.data.map(i => i.id)]
			data.items.properties.courier.properties.location.enum = locations

			//	remove this line if need to support bulk
			data = data.items
			return data
		}
	}
}

export default {
	name: 'UserNew',
	components: { NewPageGen },
	data: () => config,
	errorCaptured(err) {
		const knownErrors = ['Location not found']
		if (typeof err === 'string' && knownErrors.includes(err)) {
			return false
		}
	}
}
</script>
<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>
