<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import EditPageGen from './EditPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'
import { getLocations } from '@/api/locations-api'

/*
	We need these data/schema modifier that adds the "order" field to avoid relying on the buggy backoffice dragndrop of arrays elements to sort the menu items.
	The order field will only be visible on the backoffice and will be removed before sending the sorted array to the API
 */
const config = {
	options: {
		resource: 'courier-menu',
		recordId: '', // there's only one resource for courier-menu
		method: 'PUT',
		onSuccessRedirectTo: '/courier-menu',
		schemaModifier: async schema => {
			// Need to change the schema so the user can input the order
			Object.assign(
				schema.properties.config.properties.items.items.properties,
				{
					order: {
						type: 'integer',
						minimum: 0,
						maximum: 1000
					}
				}
			)
			let response = await getLocations()
			const locations = response.data.map(i => i.id)
			schema.properties.config.properties.items.items.properties.locations.items.enum =
				locations

			response = await axiosApiClient({ url: `/locales?limit=100` })
			const locales = response.data.map(i => i.id)
			schema.properties.config.properties.items.items.properties.translations.items.properties.locale.enum =
				locales
			return schema
		},
		modifier: async data => {
			// Fill the "fake" order attribute with the index of the menu items array
			data.config.items.forEach((v, k) => {
				v.order = k * 2 // * 2 to put some space between order values so we can reorder easily
			})
			return data
		},
		preSubmitModifier: async data => {
			// Sort the menu items by the user specified orders before sending to the api
			data.config.items.sort((x, y) => x.order - y.order)
			data.config.items.forEach(v => {
				delete v.order
			})
			return data
		}
	}
}

export default {
	name: 'CourierMenu',
	components: { EditPageGen },
	data: () => config
}
</script>
