<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import ListPageGen from './ListPageGen.vue'
import WebhookRetry from './WebhookRetry.vue'

const config = {
	options: {
		resource: 'webhooks',
		display: [
			{ key: 'id' },
			{ key: 'type' },
			{ key: 'action' },
			{ key: 'status' },
			{ key: 'createdAt' }
		],
		canCreateNew: false,
		canEdit: false,
		canDelete: false,
		syncEnabled: false,
		modifier: async data => {
			data.forEach(d => {
				const { status } = d
				const component = new Vue(
					Object.assign({}, WebhookRetry, {
						data: () => ({ status, id: d.id })
					})
				)
				d.status = component
			})
			return data
		}
	}
}

export default {
	name: 'WebhookList',
	components: { ListPageGen },
	data: () => config
}
</script>
