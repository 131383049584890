<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import UserGenerateToken from './UserGenerateToken.vue'
import DetailsPageGen from './DetailsPageGen.vue'
const { VUE_APP_ENABLE_LLMP_329_API_KEY_GENERATION } = process.env
const tokenGenerationEnabled =
	VUE_APP_ENABLE_LLMP_329_API_KEY_GENERATION === 'true' ? true : false
const display = [
	'name',
	'clients',
	'aliases',
	'roles',
	'tags',
	'createdAt',
	'updatedAt',
	'isVerified',
	'driverId',
	'locationId'
]
if (tokenGenerationEnabled) {
	display.push('token')
}

const config = {
	options: {
		resource: 'users',
		display,
		modifier: async (ctx, data) => {
			data.name = data.info.name
			data.aliases = data.aliases.map(a => ({ [a.type]: a.id }))
			if (tokenGenerationEnabled) {
				const component = new Vue({
					...UserGenerateToken,
					data: () => ({ id: data.id, token: '', expiryDate: '' })
				})
				data.token = component
			}

			data.driverId = data.driverId ? data.driverId : ''
			data.locationId = data.locationId ? data.locationId : ''
			return data
		}
	}
}

export default {
	name: 'UserDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
