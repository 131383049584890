<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'clients',
		display: [
			{ key: 'id' },
			{ key: 'logo' },
			{ key: 'name' },
			{ key: 'createdAt' }
		],
		modifier: async data =>
			data.map(r => {
				const { logo } = r.translations[0]
				const name = r.translations.map(t => ({ [t.locale]: t.name }))
				return Object.assign({}, r, { logo, name })
			})
	}
}

export default {
	name: 'ClientList',
	components: { ListPageGen },
	data: () => config
}
</script>
