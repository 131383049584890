<script>
import _ from 'lodash'
import Layout from '@/components/Layout.vue'
import CommonTable from '@/components/CommonTable.vue'
import FormFilter from './components/FormFilter.vue'
import ActionButtons from '@/components/ActionButtons.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'Locations',
	components: {
		CommonTable,
		Layout,
		ActionButtons,
		FormFilter
	},
	data() {
		return {
			moduleName: 'locations',
			actionButtons: {
				commonButtons: ['new'],
				targetButtons: ['detail', 'edit', 'delete', 'copy']
			},
			columns: [
				{
					name: 'id',
					label: 'ID',
					field: 'id',
					align: 'left',
					sortable: true,
					style: 'width: 200px;'
				},
				{
					name: 'translations',
					label: 'Translations',
					field: 'translations',
					align: 'left',
					style: 'min-width: 400px;'
				},
				{
					name: 'actions',
					label: 'Actions',
					align: 'left',
					style: 'width: 200px;'
				}
			]
		}
	},
	computed: {
		...mapState('locations', ['totalPage', 'data', 'filter'])
	},
	beforeDestroy() {
		this.resetFilter()
	},
	methods: {
		...mapActions('locations', ['getLocations', 'resetFilter']),
		async onPageChange(pagination) {
			const { sortBy, descending, page, limit } = pagination
			await this.getLocations({
				sortBy: sortBy || 'id',
				sortDirection: sortBy && !descending ? 'ASC' : 'DESC',
				page,
				limit
			})
		}
	}
}
</script>

<template>
	<layout class-name="locations-page" title="Locations">
		<form-filter
			permission-module-name="locations"
			:common-buttons="actionButtons.commonButtons"
		/>
		<common-table
			row-key="id"
			:data="data"
			:columns="columns"
			:on-page-change="onPageChange"
			:total-page="totalPage"
			:current-page="filter.page"
			:current-sort="{
				sortBy: filter.sortBy,
				descending: filter.sortDirection === 'DESC'
			}"
			binary-state-sort
		>
			<template #actions="{ row }">
				<action-buttons
					permission-module-name="locations"
					:target-buttons="{
						itemId: row.id,
						item: row,
						buttonList: actionButtons.targetButtons
					}"
				/>
			</template>
		</common-table>
	</layout>
</template>
