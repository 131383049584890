<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import _ from 'lodash'
import EditPageGen from './EditPageGen.vue'

const config = {
	options: {
		name: 'Order Spreadsheet Formats',
		resource: 'order-spreadsheet-formats',
		modifier: async data => {
			data.attributes.clientRef = _.flatten([data.attributes.clientRef])
			return data
		}
	}
}

export default {
	name: 'OrderSpreadsheetFormatsEdit',
	components: { EditPageGen },
	data: () => config
}
</script>
