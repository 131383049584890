<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import EditPageGen from './EditPageGen.vue'

const config = {
	options: {
		resource: 'api-tokens'
	}
}

export default {
	name: 'ApiTokensEdit',
	components: { EditPageGen },
	data: () => config
}
</script>
