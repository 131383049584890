<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import EditPageGen from './EditPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'

const config = {
	options: {
		resource: 'summary-status-actions',
		schemaModifier: async (schema, data) => {
			const { clientId } = data
			const response = await axiosApiClient({
				url: `/summary-item-actions?clientId=${clientId}*&limit=100`
			})
			const actions = response.data.map(i => i.id)
			schema.properties.summaryItemActions.items.enum = actions || []
			return schema
		}
	}
}

export default {
	name: 'SummaryStatusActionsEdit',
	components: { EditPageGen },
	data: () => config
}
</script>
