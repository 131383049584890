<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import _ from 'lodash'
import EditPageGen from './EditPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'

const config = {
	options: {
		resource: 'summary-item-actions',
		schemaModifier: async (schema, data) => {
			let response = await axiosApiClient({
				url: `/form-fields?id=COMMON_*&limit=100`
			})
			const fields = response.data.map(f => f.tag)
			response = await axiosApiClient({
				url: `/form-fields?id=${data.clientId}_*&limit=100`
			})
			fields.push(...response.data.map(f => f.tag))
			response = await axiosApiClient({
				url: `/forms?id=COMMON_*&limit=200`
			})
			const forms = response.data.map(f => f.id)
			response = await axiosApiClient({
				url: `/forms?id=${data.clientId}_*&limit=200`
			})
			forms.push(...response.data.map(f => f.id))
			schema.properties.input.properties.formId.enum = forms
			schema.properties.input.properties.fields.items.properties.tag.enum =
				_.uniq(fields)
			return schema
		}
	}
}

export default {
	name: 'SummaryItemActionsEdit',
	components: { EditPageGen },
	data: () => config
}
</script>
