<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import EditPageGen from './EditPageGen.vue'

const config = {
	options: {
		resource: 'pickup-points',
		schemaModifier: async data => {
			data.autocompleteFields = [
				{
					name: 'clientId', // name of the field in jsonschema
					resource: 'clients',
					query: 'id',
					display: 'id' // field from response to be displayed in the suggestion
				}
			]
			return data
		}
	}
}

export default {
	name: 'PickupPointEdit',
	components: { EditPageGen },
	data: () => config
}
</script>
