<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import EditPageGen from './EditPageGen.vue'

const config = {
	options: {
		resource: 'locales'
		// modifier: async data => {
		// 	data.locales = data.locales.map(i => i.id)
		// 	return data
		// },
	}
}

export default {
	name: 'LocationEdit',
	components: { EditPageGen },
	data: () => config
}
</script>
