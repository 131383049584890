import axiosApiClient from './axiosApiClient'
import _ from 'lodash'

export const getOrderSpreadsheetFormats = async () => {
	const response = await axiosApiClient({
		method: 'GET',
		url: '/order-spreadsheet-formats',
		params: {
			limit: 50
		}
	})
	return response.data
}
