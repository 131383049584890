<template>
	<div id="user-generate-token">
		<div>
			<input id="userToken" :value="token" />
			<button @click="generateToken">Generate</button>
			<button v-clipboard="copyToken">Copy</button>
		</div>
		<div v-if="token" id="expiry">
			<span id="expiryKey">Expiry Date:</span>
			<span>{{ expiryDate }}</span>
		</div>
	</div>
</template>

<script>
import jsonwebtoken from 'jsonwebtoken'
import moment from 'moment-timezone'
import axiosApiClient from '../api/axiosApiClient'
import log from '@/lib/utils/log'

export default {
	name: 'UserGenerateToken',
	methods: {
		async generateToken() {
			try {
				const response = await axiosApiClient({
					method: 'POST',
					url: `users/${this.id}/token`
				})
				const { token } = response.data
				this.token = token
				const parsedToken = jsonwebtoken.decode(token, {
					complete: true
				})
				const { exp } = parsedToken.payload
				const expiryDate = new Date(exp * 1000)
				this.expiryDate = moment
					.tz(expiryDate, moment.tz.guess())
					.format('llll (z)')
			} catch (e) {
				log.error('UserGenerateToken', { category: 'API' }, e.stack)
				alert(e)
			}
		},
		copyToken() {
			return this.token
		}
	}
}
</script>

<style lang="scss" scoped>
#user-generate-token {
	button {
		margin-left: 10px;
	}

	#expiry {
		padding-top: 10px;

		#expiryKey {
			font-weight: bold;
		}
	}
}
</style>
