<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'
import log from '@/lib/utils/log'
import _ from 'lodash'

const config = {
	options: {
		resource: 'form-submissions',
		canEdit: false,
		canDelete: false,
		display: [
			'clientId',
			'user',
			'tag',
			'dockId',
			'dockStatus',
			'fields',
			'meta',
			'submittedAt',
			'createdAt',
			'updatedAt'
		],
		recordQueryParam: {},
		modifier: async (ctx, data) => {
			data.dockStatus = '-'
			data.dockId = '-'
			if (data.tag.startsWith('CROSSDOCK_')) {
				const statusIcons = {
					REVIEWING: '🟡',
					REJECTED: '🔴',
					APPROVED: '🟢'
				}

				let dockId = '',
					dockStatus = ''
				for (const { tag, values } of data.fields) {
					if (tag === 'DOCKID') {
						dockId = values[0]
					}
					if (tag === 'DOCKSTATUS') {
						dockStatus = values[0]
					}
					if (dockId && dockStatus) {
						break
					}
				}
				if (dockStatus) {
					data.dockStatus = `${dockStatus} ${statusIcons[dockStatus]}`
				}
				if (dockId) {
					data.dockId = dockId.substring(0, 7).toUpperCase()
				}
			}

			data.fields = data.fields.map(f => {
				const { tag } = f
				delete f.tag
				return { [tag]: [f] }
			})
			const phone = _.get(
				_.find(_.get(data, 'user.aliases'), { type: 'PHONE' }),
				'id',
				'N/A'
			)
			const name = _.get(data, 'user.info.name', 'N/A')
			data.user = `${name} ${phone}`
			if ((data.meta || {}).backofficeMeta) {
				const { userId } = data.meta.backofficeMeta
				try {
					const response = await axiosApiClient({
						method: 'GET',
						url: `/users/${userId}`
					})
					const { info, aliases } = response.data
					data.meta.backofficeMeta.user = `${info.name} ${aliases.map(
						i => i.id
					)}`
					delete data.meta.backofficeMeta.userId
				} catch (e) {
					log.error(
						'Error on getting backoffice user info',
						null,
						e.stack
					)
				}
			}
			return data
		}
	}
}

export default {
	name: 'FormSubmissionsDetails',
	components: { DetailsPageGen },
	data() {
		if (this.$route.query.isDeleted !== undefined) {
			config.options.recordQueryParam.isDeleted =
				this.$route.query.isDeleted === true ||
				this.$route.query.isDeleted === 'true'
		}

		return config
	}
}
</script>
