<template>
	<div id="edit-page-gen">
		<side-nav />
		<div v-if="ready" id="content">
			<h2>
				{{ name }}
			</h2>
			<json-form
				:id="recordId"
				:schema="schema"
				:modifier="preSubmitModifier"
				:value="value"
				:url="url"
				:method="method"
				:error-modifier="errorModifier"
				:on-success-redirect-to="onSuccessRedirectTo"
				:submit-permission="editPermissions"
				:on-initialized="onFormInitialized"
			/>
		</div>
	</div>
</template>

<script>
import S from 'string'
import axiosApiClient from '../api/axiosApiClient'
import { rolePermissionCheck } from '../permission/rolePermission'
import JsonForm from './JsonForm.vue'
import SideNav from './SideNav.vue'

export default {
	components: { JsonForm, SideNav },
	props: {
		options: { type: Object, required: true },
		onFormInitialized: { type: Function, default: () => {} }
	},
	data() {
		const recordId =
			this.options.recordId === undefined
				? this.$route.params.id
				: this.options.recordId
		const ctx = {
			id: this.options.id || 'id',
			name: `${
				this.options.name || S(this.options.resource).humanize().s
			}: ${recordId}`,
			modifier: this.options.modifier || (async data => data),
			schema: this.options.schema,
			schemaModifier:
				this.options.schemaModifier || (async (schema, data) => schema),
			preSubmitModifier: this.options.preSubmitModifier
				? data =>
						this.options.preSubmitModifier(data, {
							...this
						})
				: async data => data,
			errorModifier: this.options.errorModifier || (data => data),
			onSuccessRedirectTo: this.options.onSuccessRedirectTo,
			url: this.options.resource,
			editPermissions: rolePermissionCheck(this.options.resource, 'edit'),
			recordId: recordId,
			value: {},
			method: this.options.method || 'PATCH',
			ready: false
		}
		const initData = async () => {
			const url = this.options.resource
			const { modifier, recordId } = ctx
			const response = await axiosApiClient({ url: `${url}/${recordId}` })
			ctx.value = await modifier(response.data)
			if (ctx.schema === undefined) {
				const response = await axiosApiClient({
					url: `${url}/schemas/${ctx.method}`
				})
				ctx.schema = await ctx.schemaModifier(
					response.data.body || response.data,
					ctx.value
				)
			}
			ctx.ready = true
		}
		initData()
		return ctx
	}
}
</script>

<style>
#edit-page-gen {
	width: 98%;
	margin: auto;
	display: flex;
	position: absolute;
}
#content {
	width: 100%;
}
</style>
