<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'locales',
		display: [{ key: 'id' }, { key: 'translations' }],
		modifier: async data =>
			data.map(r => {
				const translations = r.translations
					.map(t => t.value)
					.reduce((a, v) => {
						if (!a.includes(v)) {
							a.push(v)
						}
						return a
					}, [])

				return Object.assign({}, r, { translations })
			})
	}
}

export default {
	name: 'LocaleList',
	components: { ListPageGen },
	data: () => config
}
</script>
