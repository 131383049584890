<script>
/* global $, Bloodhound */

import _ from 'lodash'
import moment from 'moment-timezone'
import S from 'string'
import Ajv from 'ajv'
import axiosApiClient from '../api/axiosApiClient'
import { uploadImageToS3 } from '../api/s3Client'
import log from '@/lib/utils/log'
import { convertFileToBase64 } from '../lib/utils/convertFileToBase64'
import { Notify } from 'quasar'

const dateKeys = []
const dateOptions = {}
const colorFields = []

const dateValToUTC = function (values) {
	dateKeys.forEach(k => {
		if (!values[k]) {
			delete values[k]
			return
		}
		const utcDate = new Date(values[k])
		values[k] = utcDate.toISOString()
	})
	return values
}

const dateValToLocatTZ = function (values) {
	dateKeys.forEach(k => {
		const timezone = moment.tz.guess()
		const localDateTime = moment
			.tz(values[k], timezone)
			.format('YYYY-MM-DDTHH:mm')
		values[k] = localDateTime
	})
	return values
}

function setTitleAndRequireFields(obj, required) {
	const keys = Object.keys(obj)
	if (keys.includes('properties')) {
		let requiredFields = []
		if (keys.includes('required')) {
			requiredFields = obj.required
			delete obj.required
		}
		return setTitleAndRequireFields(obj.properties, requiredFields)
	}
	keys.forEach(k => {
		if (!Array.isArray(obj[k]) && typeof obj[k] === 'object') {
			obj[k].title =
				obj[k].title !== undefined ? obj[k].title : S(k).humanize().s
			if (required !== undefined && Array.isArray(required)) {
				obj[k].required =
					obj[k].required !== undefined
						? obj[k].required
						: required.includes(k)
			}
			if (obj[k].type === 'string' && obj[k].format === 'date-time') {
				obj[k].type = 'datetime'
				dateOptions[k] = {
					minDate: obj[k].minDate,
					maxDate: obj[k].maxDate
				}
				delete obj[k].minDate
				delete obj[k].maxDate
				dateKeys.push(k)
			}
			if (obj[k].type === 'string' && obj[k].format === 'time') {
				obj[k].type = 'time'
			}
			if (k.endsWith('Color')) {
				obj[k].type = 'color'
			}
			setTitleAndRequireFields(obj[k])
		}
	})
	return obj
}

function generateSubform(
	parentKey,
	notitle,
	schema,
	routePath,
	isArray = true
) {
	const keySeparator = isArray ? '[]' : ''
	const subItems = []
	const subKeys = Object.keys(schema)
	subKeys.forEach(sk => {
		if (schema[sk].type === 'array' && schema[sk].items.type === 'object') {
			subItems.push(
				generateSubform(
					`${parentKey}${keySeparator}.${sk}`,
					false,
					schema[sk].items.properties,
					routePath,
					true
				)
			)
			return
		}
		if (schema[sk].type === 'color') {
			delete schema[sk].pattern
			colorFields.push(`${parentKey}${keySeparator}.${sk}`)
		}
		if (!schema[sk].file) {
			const item = {
				key: `${parentKey}${keySeparator}.${sk}`,
				valueInLegend: schema[sk].valueInLegend
					? schema[sk].valueInLegend
					: false
			}
			subItems.push(item)
			return
		}
		const item = {
			key: `${parentKey}${keySeparator}.${sk}`,
			append: `<input type="file" id="${parentKey}${keySeparator}.${sk}"></input>`,
			onChange(evt) {
				const Body =
					evt.target.type === 'file' ? evt.target.files[0] : null
				if (!Body) {
					return
				}
				const imgName = Body.name.substr(0, Body.name.lastIndexOf('.'))
				const imgExt = Body.name.substr(Body.name.lastIndexOf('.') + 1)
				const bucket = process.env.VUE_APP_AWS_S3_BUCKET_ALBUM_NAME
				const env = process.env.VUE_APP_ENV
				const Key = `${bucket}/llmplus${routePath}/${env}/${encodeURIComponent(
					`${imgName}-${Date.now()}.${imgExt}`
				)}`
				const upload = async () => {
					try {
						const data = await uploadImageToS3(
							Key,
							await convertFileToBase64(Body)
						)
						$(`input[name='${evt.target.id}']`).val(data.Location)
					} catch (error) {
						alert(
							'There was an error uploading the photo: ',
							error.message
						)
					}
				}
				upload()
			}
		}
		subItems.push(item)
	})
	const title = parentKey.split('.')
	let formField
	if (isArray) {
		let items = [
			{
				type: 'section',
				legend: '{{idx}}. {{value}}',
				items: subItems
			}
		]
		// if items is more than 1 group fields and make expandable
		if (subItems.length > 0) {
			items = [
				{
					type: 'fieldset',
					legend: '{{idx}}',
					title: title[title.length - 1],
					// notitle: true,
					expandable: true,
					items: subItems
				}
			]
		}
		formField = {
			type: 'fieldset',
			title: title[title.length - 1],
			notitle,
			expandable: true,
			valuePath: parentKey,
			items: [
				{
					type: 'array',
					title: title[title.length - 1],
					notitle: true,
					items
				}
			]
		}
	} else {
		formField = {
			type: 'fieldset',
			title: title[title.length - 1],
			notitle,
			expandable: true,
			valuePath: parentKey,
			items: [
				{
					type: 'section',
					legend: '{{idx}}. {{value}}',
					items: subItems
				}
			]
		}
	}
	return formField
}

function generateForm(schema, routePath, displaySubmitBtn) {
	const form = []
	const keys = Object.keys(schema)

	keys.forEach(k => {
		if (schema[k].type !== 'array') {
			if (schema[k].type === 'object') {
				const formField = generateSubform(
					k,
					schema[k].notitle,
					schema[k].properties,
					routePath,
					false
				)
				form.push(formField)
				return
			}
			if (!schema[k].custom) {
				form.push(k)
				return
			}
			schema[k].custom.forEach(i => {
				form.push(i)
			})
			return
		}
		if (schema[k].items.type !== 'object') {
			const notitle =
				schema[k].type === 'array' ? true : schema[k].notitle || false
			const item = { key: k, notitle }
			form.push({
				type: 'fieldset',
				title: k,
				expandable: true,
				notitle: schema[k].notitle || false,
				valuePath: k,
				items: [item]
			})
			return
		}
		const formField = generateSubform(
			k,
			schema[k].notitle,
			schema[k].items.properties,
			routePath,
			true
		)
		form.push(formField)
	})

	if (displaySubmitBtn) {
		form.push({
			type: 'submit',
			title: 'Submit'
		})
	}
	return form
}

export default {
	name: 'JsonForm',
	props: [
		'schema',
		'modifier',
		'value',
		'url',
		'method',
		'id',
		'errorModifier',
		'navigateTo',
		'onSuccessRedirectTo',
		'submitPermission',
		'onInitialized'
	],
	data() {
		return {
			formError: null
		}
	},
	watch: {
		formError(newValue) {
			if (newValue) {
				throw newValue
			}
		}
	},
	async mounted() {
		const token = localStorage.getItem('token')
		const routePath = this.$route.path
		const { mode } = this.$route.query
		const store = this.$store
		let { schema } = this
		const { type, autocompleteFields, disabledFields, hiddenFields } =
			schema
		delete schema.autocompleteFields
		delete schema.disabledFields
		delete schema.hiddenFields
		if (type === 'array') {
			schema = Object.assign({}, { form: schema })
			schema.form.notitle = true
		}
		const rawSchema = _.cloneDeep(schema)
		let defaults = Object.assign({}, schema.properties)
		defaults = Object.keys(defaults).reduce((accumulator, key) => {
			const { type } = defaults[key]
			const defaultVal = defaults[key].default
			switch (type) {
				case 'array':
					accumulator[key] = defaultVal || []
					break
				case 'object':
					accumulator[key] = defaultVal || {}
					break
				default:
					break
			}
			return accumulator
		}, {})
		schema = setTitleAndRequireFields(schema)
		const onSuccessRedirectTo = this.onSuccessRedirectTo
		const { id } = this
		const { method } = this
		const baseUrl = this.url
		const router = this.$router
		const baseRedirectUrl = this.$route.path.split('/') // ["", base, "param"]
		const navigateTo =
			this.navigateTo === undefined
				? baseRedirectUrl[1]
				: `${baseRedirectUrl[1]}/${this.navigateTo}`
		const modifier = this.modifier || (async data => data)
		const form = generateForm(schema, routePath, this.submitPermission)
		const errorModifier = this.errorModifier || (error => error)
		let { value } = this
		const valueToRestore = Object.assign({}, value)
		if (mode === 'undo') {
			let previousData = localStorage.getItem(routePath)
			const ajv = new Ajv({
				removeAdditional: true,
				useDefaults: 'empty'
			})
			const schemaUrl = `${baseUrl}/schemas/patch`
			const response = await axiosApiClient(schemaUrl)
			const patchSchema = response.data.body || response.data
			const patchRemoveAdditional = ajv.compile(patchSchema)
			previousData = JSON.parse(previousData)
			patchRemoveAdditional(previousData)
			value = await modifier(previousData)
		}
		if (value) {
			value = dateValToLocatTZ(value)
		}

		const submitData = async values => {
			try {
				if (type === 'array') {
					values = values.form
				}
				const data = await modifier(values)
				const roles = _.get(data, 'roles', [])
				const clients = _.get(data, 'clients', [])
				if (_.isEqual(roles, ['CLT']) && clients.length === 0) {
					Notify.create({
						message:
							'Users with the "CLT" role must be tied to at least one "Clients".',
						type: 'negative',
						position: 'top'
					})
					return
				}
				const url = `${baseUrl}/${id || ''}`
				const request = { method, url, data }
				const response = await axiosApiClient(request)
				alert('Successfully submitted!')
				if (method === 'PATCH') {
					localStorage.setItem(
						routePath,
						JSON.stringify(valueToRestore)
					)
				}
				response.data = _.castArray(response.data)
				if (navigateTo === 'api-tokens') {
					const id = response.data[0].id
					const token = response.data[0].token
					store.dispatch('apiTokens/addToList', { id, token })
				}
				if (onSuccessRedirectTo !== undefined) {
					router.replace({ path: onSuccessRedirectTo })
				} else {
					router.replace({
						path: `/${navigateTo}/${response.data[0].id}`
					})
				}
			} catch (e) {
				log.error(`${method} ${baseUrl}`, { category: 'API' }, e.stack)
				let formError = e

				// Show alert if errors is here
				if (
					(e.response.status === 422 || e.response.status === 403) &&
					typeof _.get(e, 'response.data.errors.0.detail') ===
						'string'
				) {
					formError = e.response.data.errors[0].detail
					alert(formError)
				}

				this.formError = formError
			}
		}

		const jsonFormParams = {
			schema,
			form,
			value,
			onSubmit(errors, values) {
				errors = errors ? errors.filter(i => i) : []
				if (errors.length > 0) {
					const field = S(errors[0].uri.split('/').pop()).humanize().s
					alert(`${errors[0].message}: ${field}`)
					return
				}
				values = dateValToUTC(values)
				values = Object.assign({}, defaults, values)
				submitData(values)
			},
			displayErrors: function (errors, formElt) {
				for (const index in errors) {
					errors[index] = errorModifier(errors[index])
				}
				errors = errors.filter(i => i)
				if (errors.length === 0) {
					return
				}
				$(formElt).jsonFormErrors(errors)
			},
			rawSchema
		}

		$('#json-form-fields').llmpJsonForm(jsonFormParams)

		dateKeys.forEach(k => {
			$(`input[name='${k}']`).datetimepicker(
				_.omitBy(
					{
						sideBySide: true,
						date: value ? new Date(value[k]) : null,
						...dateOptions[k]
					},
					_.isNil
				)
			)
		})

		if (colorFields.length > 0) {
			colorFields.forEach(i => {
				$(`input[name='${i}']`).spectrum({
					preferredFormat: 'hex',
					showInput: true,
					allowEmpty: true
				})
			})
		}

		// eslint-disable-next-line no-extra-semi
		;(autocompleteFields || []).forEach(field => {
			const { name, resource, query, display } = field
			let url = `${process.env.VUE_APP_API_URL}/${resource}/%QUERY*`
			if (query) {
				url = `${process.env.VUE_APP_API_URL}/${resource}?${query}=%QUERY*`
			}
			const data = new Bloodhound({
				datumTokenizer: Bloodhound.tokenizers.whitespace,
				queryTokenizer: Bloodhound.tokenizers.whitespace,
				remote: {
					url,
					prepare: function (query, settings) {
						settings.url = settings.url.replace(
							'%QUERY',
							encodeURIComponent(query)
						)
						settings.headers = {
							Authorization: `Bearer ${token}`
						}
						return settings
					}
				}
			})
			$(`input[name='${name}']`).attr('autocomplete', 'off')
			$(`input[name='${name}']`).typeahead(
				{
					hint: false,
					highlight: true,
					minLength: 3,
					classNames: {
						menu: 'typeahead-menu'
					}
				},
				{
					display: display,
					source: data,
					limit: 5
				}
			)
			$(`input[name='${name}']`).css('width', '100%')
			const noValueDisplay = $(`input[name='${name}']`)
				.parent('.twitter-typeahead')
				.prev('#_jsonform-input-no-value-text')
				.css('display')
			if (noValueDisplay === 'block') {
				$(`input[name='${name}']`).parent('.twitter-typeahead').hide()
			}
			if (!noValueDisplay) {
				$(`input[name='${name}']`)
					.parent('.twitter-typeahead')
					.css('width', '100%')
			}
		})

		if (disabledFields) {
			disabledFields.forEach(element => {
				$(element).prop('disabled', 'disabled')
			})
		}

		if (hiddenFields) {
			for (const [relation, selector] of Object.entries(hiddenFields)) {
				if (relation == 'self') {
					selector.forEach(element => {
						$(element).css('display', 'none')
					})
				} else {
					for (const [element, extra] of Object.entries(selector)) {
						$(element)[relation](extra).css('display', 'none')
					}
				}
			}
		}

		if (typeof this.onInitialized === 'function') {
			this.onInitialized()
		}
	}
}
</script>

<template>
	<div class="json-form">
		<form id="json-form-fields" />
	</div>
</template>

<style>
.jsonform-required > label:after {
	content: '*';
	color: red;
}
.expandable > legend:before {
	content: '\25B8';
	padding-right: 5px;
}
.expanded > legend:before {
	content: '\25BE';
}
._jsonform-array-buttons {
	padding-inline-start: 40px;
}
.controls {
	position: relative;
}
.twitter-typeahead {
	width: 90%;
}
.tt-query,
.tt-hint {
	width: 100%;
	height: 30px;
	padding: 8px 12px;
	font-size: 24px;
	line-height: 30px;
	border: 2px solid #ccc;
	border-radius: 8px;
	outline: none;
}
.tt-query {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
	color: #fff;
}
.typeahead-menu {
	/* width: 90%; */
	margin-top: 12px;
	padding: 8px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tt-suggestion {
	cursor: pointer;
	padding: 3px 20px;
	font-size: 18px;
	line-height: 24px;
}
.tt-suggestion.tt-is-under-cursor {
	color: #fff;
	background-color: #0097cf;
}
.tt-suggestion p {
	margin: 0;
}

.jsonform-errortext {
	color: red;
}

._jsonform-input-add,
._jsonform-input-delete,
._jsonform-move-up,
._jsonform-move-down {
	margin-left: 10px;
}
</style>
