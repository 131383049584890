<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		name: 'Order Spreadsheet Formats',
		resource: 'order-spreadsheet-formats',
		display: [
			{ key: 'id' },
			{ key: 'clientRef' },
			{ key: 'addressText' },
			{ key: 'createdAt' }
		],
		modifier: async data =>
			data.map(r => {
				const clientRef = r.attributes.clientRef
				const addressText = r.attributes.addressText
				return Object.assign({}, r, { clientRef, addressText })
			})
	}
}

export default {
	name: 'OrderSpreadsheetFormatsList',
	components: { ListPageGen },
	data: () => config
}
</script>
