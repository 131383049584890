<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
/* global _ */
import moment from 'moment-timezone'
import DetailsPageGen from './DetailsPageGen.vue'
import { getLocationTimezone } from '../api/locations-api'

const config = {
	options: {
		canEdit: true,
		resource: 'deliveries',
		modifier: async (ctx, data) => {
			if (data.courier) {
				const phone = (
					data.courier.aliases.find(i => i.type === 'PHONE') || {}
				).id
				data.courier = { name: data.courier.info.name, phone }
			}

			const locationId = _.get(data, 'order.locationId')
			// show some date in order timezone
			const tz = locationId
				? await getLocationTimezone(locationId)
				: moment.tz.guess()

			const order = data.order
				? {
						pickupAt: `rawdate-${moment(
							_.get(data, 'order.pickupAt')
						)
							.tz(tz)
							.format('YYYY-MM-DD HH:mm (UTCZ)')}`,
						deliveryBy: `rawdate-${moment(
							_.get(data, 'order.deliveryBy')
						)
							.tz(tz)
							.format('YYYY-MM-DD HH:mm (UTCZ)')}`
				  }
				: null

			_.merge(data, {
				pickupAt: `rawdate-${moment(data.pickupAt)
					.tz(tz)
					.format('YYYY-MM-DD HH:mm (UTCZ)')}`,
				deliveryBy: `rawdate-${moment(data.deliveryBy)
					.tz(tz)
					.format('YYYY-MM-DD HH:mm (UTCZ)')}`,
				order
			})

			return data
		}
	}
}

export default {
	name: 'DeliveriesDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
