<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import ListPageGen from './ListPageGen.vue'
import Link from '@/components/Link.vue'

const config = {
	options: {
		name: 'Orders Spreadsheet Submissions',
		resource: 'order-imports',
		syncEnabled: false,
		canCreateNew: false,
		canEdit: false,
		canDelete: false,
		display: [
			{ key: 'id' },
			{ key: 'clientId' },
			{ key: 'orderSpreadsheetFormatId' },
			{ key: 'file' },
			{ key: 'createdRequestsNum', name: 'Created Requests' },
			{ key: 'updatedRequestsNum', name: 'Updated Requests' },
			{ key: 'createdAt' },
			{ key: 'updatedAt' }
		],
		modifier: async list =>
			list.map(item => {
				const file = new Vue({
					...Link,
					data: () => ({ href: item.fileUrl, value: item.fileName })
				})
				return { ...item, file }
			})
	}
}

export default {
	name: 'OrdersSpreadsheetSubmissionsList',
	components: { ListPageGen },
	data: () => config
}
</script>
