<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'clients',
		display: [
			'logo',
			'locations',
			'domains',
			'orderTags',
			'attributes',
			'canCancelOrders',
			'createdAt',
			'updatedAt'
		],
		modifier: async (ctx, data) => {
			const logo = []
			data.translations.forEach(t => {
				logo.push(t.logo)
				data[t.locale] = t.name
				if (ctx.mapping.length > 0) {
					ctx.mapping.splice(0, 0, {
						header: t.locale,
						key: t.locale
					})
				}
			})
			data.logo = logo

			// remove nulls to fix rendering issues
			data.orderTags = data.orderTags.map(ot => {
				if (!ot.fieldPath) {
					delete ot.fieldPath
				}
				if (!ot.sheetColumnName) {
					delete ot.sheetColumnName
				}
				return ot
			})

			return data
		}
	}
}

export default {
	name: 'ClientDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
