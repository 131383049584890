<template>
	<div id="deliveries-form-submit">
		<div id="deliveriesFormSubmit">
			<div
				class="form-group jsonform-error-courierId jsonform-required"
				style="width: 95%"
			>
				<div class="controls">
					<input
						v-model="courier"
						placeholder="eg. +85255500000"
						class="form-control"
						required="required"
						autocomplete="off"
						@keyup="search"
					/>
					<table id="result" style="width: 100%">
						<tr v-for="(user, k) in users" :key="k">
							<td>
								{{
									`${user.name}${
										user.isVerified ? '' : ' Unverified(⚠️)'
									}`
								}}
							</td>
							<td>{{ user.phone }}</td>
							<td v-if="user.isVerified">
								<button
									class="btn btn-default"
									@click="add(user)"
								>
									Add
								</button>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div
				class="form-group jsonform-error-formId---formId jsonform-required"
				style="width: 95%"
			>
				<div class="controls" style="display: flex">
					<select
						id="jsonform-5-elt-formId"
						v-model="formId"
						class="form-control"
						required="required"
					>
						<option v-for="form in formIds" :key="form">
							{{ form }}
						</option>
					</select>
					<span>
						<button
							style="margin-top: 0"
							class="btn btn-primary addbtn"
							@click="openFormSubmission"
						>
							Submit Form
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
import { getUsers } from '@/api/users-api'
import log from '@/lib/utils/log'

const { VUE_APP_ENV, VUE_APP_VERSION } = process.env

export default {
	name: 'DeliveriesFormSubmit',
	props: ['options'],
	data() {
		return {
			clientId: (this.options || {}).clientId || null,
			formIds: (this.options || {}).formIds || [],
			formId: (this.options || {}).formId || null,
			courier: (this.options || {}).courier || null,
			courierId: (this.options || {}).courierId || null,
			courierInfo: (this.options || {}).courierInfo || null,
			submissionData: (this.options || {}).submissionData || null,
			action: (this.options || {}).action || null,
			users: (this.options || {}).users || [],
			locationId: (this.options || {}).locationId || null,
			backofficeMeta: { env: VUE_APP_ENV, version: VUE_APP_VERSION }
		}
	},
	methods: {
		search: _.debounce(async function () {
			if (!this.courier || this.courier.length <= 5) {
				this.users = []
				return
			}
			try {
				// Only verified user can be find for order assign
				const response = await getUsers({
					userAliasType: 'PHONE',
					userAliasId: this.courier
				})
				this.users = response.data.map(data => {
					const { id, isVerified } = data
					const { name } = data.info
					const phone = (
						data.aliases.find(i => i.type === 'PHONE') || {}
					).value
					return { id, isVerified, name, phone }
				})
			} catch (e) {
				log.error('User search', { category: 'API' }, e.stack)
				alert(e)
			}
		}, 1000),
		async add(user) {
			const { id, name, phone } = user
			this.courier = `${id} | ${phone} | ${name}`
			this.courierId = id
			this.users = []
		},
		async openFormSubmission() {
			if (!this.formId) {
				alert('Please set Form')
				return
			}
			if (!this.courier) {
				alert('Please assign courier')
				return
			}
			if (this.formId.includes('PICKUP') && _.isArray(this.clientId)) {
				alert('PICKUP for multiple client not allowed.')
				return
			}
			if (this.formId.includes('WAREHOUSE')) {
				if (_.isArray(this.clientId)) {
					alert('WAREHOUSE drop off for multiple client not allowed.')
					return
				}
				this.submissionData['CLIENT_SELECT'] = this.clientId
			}
			if (_.isArray(this.locationId)) {
				alert('Form-submission for multiple location not allowed.')
				return
			}

			await this.$router.push({
				path: '/form-submissions/new',
				query: {
					formId: this.formId,
					data: JSON.stringify({
						userId: this.courierId,
						locationId: this.locationId,
						fields: this.submissionData
					})
				}
			})
		}
	}
}
</script>

<style scoped>
#deliveries-form-submit {
	display: inline-block;
	position: relative;
	width: 100%;
}
#deliveriesFormSubmit .form-group {
	margin-bottom: 10px;
	margin-right: 15px;
	float: left;
}
.jsonform-required > label:after {
	content: '*';
	color: red;
}
.addbtn {
	margin-top: 25px;
}
</style>
