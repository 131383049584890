<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import axiosApiClient from '../api/axiosApiClient'
import ListPageGen from './ListPageGen.vue'
import OrderRequestUserSearch from './OrderRequestUserSearch.vue'
import Vue from 'vue'
import _ from 'lodash'

const config = {
	options: {
		name: 'Orders',
		resource: 'orders',
		infoEditEnabled: true,
		syncEnabled: false,
		display: [
			{ key: 'id' },
			{ key: 'deliveryStage' },
			{ key: 'clientId' },
			{ key: 'address' },
			{ key: 'contacts' },
			{ key: 'clientRefs' },
			{ key: 'couriers', width: '30%' },
			{ key: 'pickupAt', isDateTime: true },
			{ key: 'createdAt' }
		],
		modifier: async data => {
			const orders = []
			let aliasTypes = []
			try {
				const response = await axiosApiClient.get(`/users/schemas/post`)
				aliasTypes =
					response.data.body.items.properties.aliases.items.properties
						.type.enum
			} catch (error) {
				alert(error.message)
			}
			for (const order of data) {
				const request = Object.assign({}, order)
				const componentData = {
					computed: {
						order: () => request,
						aliasTypes: () => aliasTypes
					}
				}
				const component = new Vue(
					Object.assign({}, OrderRequestUserSearch, componentData)
				)
				order.couriers = component
				order.contacts = _.flatten(
					order.contacts.map(i => Object.values(i))
				)
				// if address-level contacts exist, override root-level contacts
				if (order.address[0].contacts) {
					order.contacts = [
						..._.flatten(
							order.address[0].contacts.map(c => Object.values(c))
						)
					]
				}
				if (order.address[1] && order.address[1].contacts) {
					order.contacts = [
						...order.contacts,
						'⬇',
						..._.flatten(
							order.address[1].contacts.map(c => Object.values(c))
						)
					]
				}
				let { clientRefs } = order
				clientRefs = _.groupBy(clientRefs, 'type')
				order.clientRefs = []
				const keys = Object.keys(clientRefs).sort()
				for (const key of keys) {
					const clientRef = {
						[key]: clientRefs[key].map(i => i.value)
					}
					order.clientRefs.push(clientRef)
				}
				order.address = order.address.map(i => i.text)
				order.address.splice(1, 0, '⬇')
				orders.push(order)
			}
			return orders
		},
		filterModifier: async data => {
			data.autocompleteFields = [
				{
					name: 'clientIdAndStatus.clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				},
				{
					name: 'clientIdStatusAndPickupAt.clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				},
				{
					name: 'clientIdAndClientRef.clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				}
			]
			return data
		}
	}
}

export default {
	name: 'OrdersList',
	components: { ListPageGen },
	data: () => config
}
</script>
