<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'forms',
		display: [
			'clientId',
			'tag',
			'fields',
			'locationId',
			'createdAt',
			'updatedAt',
			'attributes'
		],
		modifier: async (ctx, data) => {
			data.translations.forEach(t => {
				data[t.locale] = t.value
				if (ctx.mapping.length > 0) {
					ctx.mapping.splice(2, 0, {
						header: t.locale,
						key: t.locale
					})
				}
			})
			data.fields = data.fields.map(f => {
				const isRequired = f.isRequired ? '*' : ''
				const isArray = f.isArray ? '(multivalue)' : ''
				const id = `${f.id}${isRequired} ${isArray}`
				if (f.options) {
					const options = f.options.map(o => o.tag)
					return { [id]: options }
				}
				return id
			})
			return data
		}
	}
}

export default {
	name: 'FormsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
