<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import DetailsPageGen from './DetailsPageGen.vue'
import Link from '@/components/Link.vue'

const config = {
	options: {
		name: 'Orders Spreadsheet Submission',
		resource: 'order-imports',
		canEdit: false,
		canDelete: false,
		display: [
			'id',
			'clientId',
			'orderSpreadsheetFormatId',
			'file',
			'createdRequests',
			'updatedRequests',
			'createdAt',
			'updatedAt'
		],
		modifier: async (ctx, data) => {
			const { fileUrl, fileName } = data
			const file = new Vue({
				...Link,
				data: () => ({ href: fileUrl, value: fileName })
			})
			delete data.fileUrl
			delete data.fileName
			data.createdRequests = data.createdRequestsNum
			data.updatedRequests = data.updatedRequestsNum
			return { ...data, file }
		}
	}
}

export default {
	name: 'OrdersSpreadsheetSubmissionsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
