<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'

const config = {
	options: {
		resource: 'routes',
		schemaModifier: async data => {
			data.autocompleteFields = [
				{
					name: 'clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				},
				{
					name: 'pickupPointId',
					resource: 'pickup-points',
					query: 'id',
					display: 'id'
				}
			]
			return data
		}
	}
}

export default {
	name: 'RouteNew',
	components: { NewPageGen },
	data: () => config
}
</script>
