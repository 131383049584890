<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'summary-item-actions',
		display: [
			'clientId',
			'tag',
			'action',
			'translations',
			'input',
			'attributes',
			'createdAt',
			'updatedAt'
		]
	}
}

export default {
	name: 'SummaryItemActionsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
