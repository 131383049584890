<template>
	<div>
		<new-page-gen
			:options="options"
			:on-form-initialized="onFormInitialized"
		/>
	</div>
</template>

<script>
import axiosApiClient from '../api/axiosApiClient'
import NewPageGen from './NewPageGen.vue'
import { fixDateOffset, changeDateInputTz } from '../lib/plugins/date'
import { getLocations } from '../api/locations-api'

const config = {
	options: {
		name: 'Orders',
		resource: 'orders',
		schemaModifier: async data => {
			data = data.items
			delete data.properties.orderImportId
			delete data.properties.requestSpreadsheetSubmissionId
			const locations = (await getLocations()).data
			data.properties.locationId.enum = locations.map(i => i.id)

			const tagsResponse = await axiosApiClient({
				url: `/order-tag-keys?limit=100`
			})
			const tags = tagsResponse.data.map(t => t.id)
			if (tags.length > 0) {
				data.properties.tags.items.properties.key.enum = tags
			} else {
				delete data.properties.tags
			}

			// resource + query will produce '/clients?id=queryString
			data.autocompleteFields = [
				{
					name: 'clientId', // name of the field in jsonschema
					resource: 'clients',
					query: 'id',
					display: 'id' // field from response to be displayed in the suggestion
				}
			]
			return data
		},
		modifier: async function (data) {
			// fix offset problem by bootstrap-datetimepicker
			const { locationId, pickupAt, deliveryBy } = data
			const tz = this.locations[locationId]
			const newPickupAt = pickupAt
				? fixDateOffset(pickupAt, tz)
				: undefined
			const newDeliveryBy = deliveryBy
				? fixDateOffset(deliveryBy, tz)
				: undefined

			return {
				...data,
				pickupAt: newPickupAt,
				deliveryBy: newDeliveryBy
			}
		},
		locations: {}
	},
	locationSelect: null
}

export default {
	name: 'OrdersNew',
	components: { NewPageGen },
	data: () => config,
	beforeDestroy: function () {
		if (this.locationSelect) {
			this.locationSelect.removeEventListener(
				'change',
				this.onLocationChange
			)
		}
	},
	methods: {
		onFormInitialized: async function () {
			const locations = (await getLocations()).data
			locations.forEach(location => {
				this.options.locations[location.id] = location.timezone
			})

			this.locationSelect = document.querySelector(
				'select[name="locationId"]'
			)
			const locationId = this.locationSelect.value
			this.locationSelect.addEventListener(
				'change',
				this.onLocationChange
			)

			// Fire to change tz on page load
			this.changeDateFieldsTz(locationId)
		},
		/**
		 * Listen value change of location select
		 *
		 * @param event
		 */
		onLocationChange: async function (event) {
			this.changeDateFieldsTz(event.target.value)
		},
		/**
		 * Update date fields manually when location changed
		 *
		 * @param locationId
		 */
		changeDateFieldsTz: function (locationId) {
			const tz = this.options.locations[locationId]
			changeDateInputTz('pickupAt', tz)
			changeDateInputTz('deliveryBy', tz)
		}
	}
}
</script>
