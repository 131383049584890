<template>
	<div id="form-submission-export">
		<side-nav />
		<div id="content">
			<h2 style="float: left">Export Form-submission</h2>
			<hr />
			<div id="formExport">
				<div
					class="form-group jsonform-error-formId---formId jsonform-required"
				>
					<label>Report Type</label>
					<div class="controls">
						<select
							id="jsonform-5-elt-reportType"
							v-model="reportType"
							class="form-control"
							required="required"
						>
							<option value="DELIVERY_LOGS">DELIVERY LOGS</option>
							<option value="EXPENSE">EXPENSE</option>
						</select>
					</div>
				</div>
				<div
					class="form-group jsonform-error-formId---formId jsonform-required"
				>
					<label>Location</label>
					<div class="controls">
						<select
							id="jsonform-5-elt-locationId"
							v-model="locationId"
							class="form-control"
							required="required"
						>
							<option
								v-for="location in locationIds"
								:key="location"
							>
								{{ location }}
							</option>
						</select>
					</div>
				</div>
				<div v-show="reportType === 'DELIVERY_LOGS'">
					<div
						class="form-group jsonform-error-formId---formId jsonform-required"
					>
						<label>Client</label>
						<div class="controls">
							<select
								id="jsonform-5-elt-clientId"
								v-model="clientId"
								class="form-control"
								required="required"
							>
								<option v-for="client in clients" :key="client">
									{{ client }}
								</option>
							</select>
						</div>
					</div>
					<div
						class="form-group jsonform-error-formId---formId jsonform-required"
					>
						<label>Date</label>
						<div class="controls">
							<input
								id="date"
								v-model="date"
								type="text"
								class="form-control"
								autocomplete="off"
							/>
						</div>
					</div>
				</div>
				<div v-show="reportType === 'EXPENSE'">
					<div
						class="form-group jsonform-error-formId---formId jsonform-required"
					>
						<label>From</label>
						<div class="controls">
							<input
								id="minDate"
								v-model="min_submittedAt"
								type="text"
								class="form-control"
								autocomplete="off"
							/>
						</div>
					</div>
					<div
						class="form-group jsonform-error-formId---formId jsonform-required"
					>
						<label>To</label>
						<div class="controls">
							<input
								id="maxDate"
								v-model="max_submittedAt"
								type="text"
								class="form-control"
								autocomplete="off"
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<button class="btn btn-primary" @click="download">
					Export
				</button>
			</div>
		</div>
	</div>
</template>

<script>
/* global $ */
import _ from 'lodash'
import moment from 'moment'
import axiosApiClient from '../api/axiosApiClient'
import SideNav from './SideNav.vue'
import log from '@/lib/utils/log'
import { getLocations } from '@/api/locations-api'

export default {
	components: { SideNav },
	data() {
		const ctx = {
			reportType: 'DELIVERY_LOGS',
			clients: [],
			clientId: undefined,
			locationId: null,
			locationIds: [],
			date: null,
			min_submittedAt: null,
			max_submittedAt: null
		}
		const initData = async () => {
			try {
				let response = await axiosApiClient({
					method: 'GET',
					url: '/clients'
				})
				const clients = response.data.map(i => i.id)
				ctx.clients.push(...clients)

				response = await getLocations()
				const locationIds = response.data.map(i => i.id)
				ctx.locationIds.push(...locationIds)
			} catch (e) {
				log.error('Init Data', null, e.stack)
				alert(e)
			}
		}
		initData()
		return ctx
	},
	async mounted() {
		const vue = this
		$('#date').datetimepicker({
			format: 'YYYY-MM-DD',
			maxDate: moment().endOf('day')
		})
		$('#minDate').datetimepicker({
			format: 'YYYY-MM-DD',
			maxDate: moment().endOf('day')
		})
		$('#maxDate').datetimepicker({
			format: 'YYYY-MM-DD',
			maxDate: moment().endOf('day')
		})
		$('#date').on('dp.change', function (e) {
			const formatedValue = e.date.format('YYYY-MM-DD')
			vue.date = formatedValue
		})
		$('#minDate').on('dp.change', function (e) {
			const formatedValue = e.date.format('YYYY-MM-DD')
			vue.min_submittedAt = formatedValue
		})
		$('#maxDate').on('dp.change', function (e) {
			const formatedValue = e.date.format('YYYY-MM-DD')
			vue.max_submittedAt = formatedValue
		})
	},
	methods: {
		async download() {
			const {
				reportType,
				clientId,
				locationId,
				date,
				min_submittedAt,
				max_submittedAt
			} = this
			switch (reportType) {
				case 'DELIVERY_LOGS':
					if (!clientId || !locationId || !date) {
						alert('Please fill in required fields')
						return
					}
					try {
						const response = await axiosApiClient({
							method: 'GET',
							url: `/form-submissions/${clientId}/${locationId}/${date}`,
							responseType: 'blob'
						})
						let filename = `${clientId}_${date}.zip`
						const content = response.headers['content-disposition']
						const matches = content.match(
							/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
						)
						if (matches != null && matches[1]) {
							filename = matches[1].replace(/['"]/g, '')
						}
						const link = document.createElement('a')
						link.href = window.URL.createObjectURL(
							new Blob([response.data])
						)
						link.download = filename
						document.body.appendChild(link)
						link.click()
						document.body.removeChild(link)
					} catch (e) {
						log.error(
							'Form-submission export',
							{ category: 'API' },
							e.stack
						)
						alert(e)
					}
					break
				case 'EXPENSE':
					if (!locationId || !min_submittedAt || !max_submittedAt) {
						alert('Please fill in required fields')
						return
					}
					try {
						const response = await axiosApiClient({
							method: 'GET',
							url: `/form-submissions/export/expense/${locationId}/${min_submittedAt}/${max_submittedAt}`,
							responseType: 'blob'
						})
						let filename = `${locationId}_${
							min_submittedAt === max_submittedAt
								? min_submittedAt
								: [min_submittedAt, max_submittedAt].join('-')
						}.zip`
						const content = response.headers['content-disposition']
						const matches = content.match(
							/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
						)
						if (matches != null && matches[1]) {
							filename = matches[1].replace(/['"]/g, '')
						}
						const link = document.createElement('a')
						link.href = window.URL.createObjectURL(
							new Blob([response.data])
						)
						link.download = filename
						document.body.appendChild(link)
						link.click()
						document.body.removeChild(link)
					} catch (e) {
						log.error(
							'Form-submission expense export',
							{ category: 'API' },
							e.stack
						)
						alert(e)
					}
					break
				default:
					alert('Invalid report type!')
			}
		}
	}
}
</script>

<style scoped>
#form-submission-export {
	width: 98%;
	margin: auto;
	display: flex;
	position: absolute;
}
#content {
	width: 100%;
}
#formExport {
	margin-bottom: 15px;
}
hr {
	width: 100%;
}
.jsonform-required > label:after {
	content: '*';
	color: red;
}
</style>
