<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'form-fields',
		display: [
			'clientId',
			'tag',
			'type',
			'isArray',
			'createdAt',
			'updatedAt',
			'attributes',
			'options'
		],
		modifier: async (ctx, data) => {
			data.translations.forEach(t => {
				data[t.locale] = t.value
				if (ctx.mapping.length > 0) {
					ctx.mapping.splice(3, 0, {
						header: t.locale,
						key: t.locale
					})
				}
			})
			if (data.options) {
				data.options = data.options.map(o => {
					const translations = o.translations.map(t => ({
						[t.locale]: t.value
					}))
					return { [o.tag]: translations }
				})
			}
			return data
		}
	}
}

export default {
	name: 'FormFieldsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
