<template>
	<div id="deliveries-submission-list">
		<h3>Submission List</h3>
		<p><i>Deliveries in this list are marked for form submission</i></p>
		<div id="deliveries-submission-list-content">
			<table id="deliveries-submission-list-table">
				<thead>
					<template v-for="m in headers">
						<th :key="m.header" :width="m.width ? m.width : null">
							{{ m.header }}
						</th>
					</template>
					<th style="width: 3%" />
				</thead>
				<tr v-for="item in deliveriesSubmissionList" :key="item.id">
					<td v-for="m in headers" :key="m.key">
						<div v-if="Array.isArray(item[m.key])">
							<div id="divTable">
								<div id="divTableBody">
									<div
										v-for="(v, k) in item[m.key]"
										id="divTableRow"
										:key="k"
									>
										<div v-if="typeof v === 'object'">
											<div
												v-for="(value, key) in v"
												:key="key"
												style="
													display: flex;
													justify-content: space-between;
													flex-wrap: wrap;
												"
											>
												<render-value
													:value="`${key}:`"
													style="font-weight: bold"
												/>
												<div
													v-if="Array.isArray(value)"
												>
													<div
														id="divTable"
														style="float: right"
													>
														<div id="divTableBody">
															<div
																v-for="(
																	val, key
																) in value"
																id="divTableRow"
																:key="key"
															>
																<render-value
																	:value="val"
																/>
															</div>
														</div>
													</div>
												</div>
												<div v-else>
													<render-value
														:value="value"
													/>
												</div>
											</div>
										</div>
										<div v-else>
											<render-value :value="v" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							v-else-if="
								typeof item[m.key] === 'object' &&
								item[m.key] !== null &&
								!item[m.key]._isVue
							"
						>
							<div
								v-for="(value, key) in item[m.key]"
								:key="key"
								style="display: flex"
							>
								<span style="font-weight: bold">
									{{ key }}:
								</span>
								<render-value :value="value" />
							</div>
						</div>
						<div v-else>
							<render-value :value="item[m.key]" />
						</div>
					</td>
					<td>
						<a
							style="font-size: 23px"
							title="delete"
							href="javascript:void(0)"
							@click="onDelete(item.id)"
						>
							❌
						</a>
					</td>
				</tr>
			</table>
			<h3>Form submit</h3>
			<p>
				<i>
					Submit a form with all deliveries in the Submission List
					above
				</i>
			</p>
			<div style="width: 50%; margin-top: 10px">
				<deliveries-form-submit :key="total" :options="options" />
			</div>
		</div>
	</div>
</template>

<script>
import S from 'string'
import RenderValue from './RenderValue.vue'
import DeliveriesFormSubmit from './DeliveriesFormSubmit.vue'
import _ from 'lodash'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

const { VUE_APP_ENV, VUE_APP_VERSION } = process.env

export default {
	name: 'DeliveriesSubmissionList',
	components: { RenderValue, DeliveriesFormSubmit },
	data() {
		const zone_name = moment.tz.guess()
		const timezone = moment.tz(zone_name).zoneAbbr()
		const tableMapping = [
			{ key: 'id', width: '5%' },
			{ key: 'clientId', width: '7%' },
			{ key: 'courier', width: '15%' },
			{
				key: 'statusHasSlaIsFlagged',
				name: 'Status,\nHas SLA,\nFlagged(⚠️)',
				width: '10%'
			}, // consolidate status, hasSla, and isFlagged into a single column
			{ key: 'clientRefs', width: '17%' }
		]
		const headers = tableMapping.map(k => {
			const { key, width, isDateTime } = k
			let { header } = k
			header = k.name === undefined ? S(k.key).humanize().s : k.name
			header =
				isDateTime || k.key === 'updatedAt' || k.key === 'createdAt'
					? header.concat(' (', timezone, ') ')
					: header
			return { header, key, width }
		})
		const ctx = {
			headers,
			options: {
				clientId: null,
				formIds: [],
				formId: null,
				courier: null,
				courierId: null,
				locationId: null,
				submissionData: null,
				users: [],
				backofficeMeta: { env: VUE_APP_ENV, version: VUE_APP_VERSION }
			}
		}
		return ctx
	},
	computed: {
		...mapState({
			deliveriesSubmissionCount: state =>
				state.deliveriesSubmissionList.count,
			deliveriesSubmissionList(state) {
				const { list } = state.deliveriesSubmissionList
				this.options.courier = null
				this.options.courierId = null
				const couriesInvolved = _.groupBy(list, 'courier')
				if (Object.keys(couriesInvolved).length === 1) {
					this.options.courier = list[0].deliveryDetails.courier
					this.options.courierId = list[0].deliveryDetails.courierId
				}
				this.options.formIds = _.uniq(
					_.flatten(list.map(i => i.deliveryDetails.formIds))
				)
				const locationIds = Object.keys(
					_.groupBy(list, 'deliveryDetails.locationId')
				)
				this.options.locationId = locationIds[0]
				if (locationIds.length > 1) {
					this.options.locationId = locationIds
				}
				const clientIds = Object.keys(
					_.groupBy(list, 'deliveryDetails.clientId')
				)
				this.options.clientId = clientIds[0]
				if (clientIds.length > 1) {
					this.options.clientId = clientIds
				}
				const submissionData = {
					BARCODE: [],
					ORDER_NO: []
				}
				list.forEach(i => {
					submissionData['BARCODE'].push(
						...i.deliveryDetails.submissionData['BARCODE']
					)
					const orderNos = i.deliveryDetails.submissionData[
						'ORDER_NO'
					].filter(
						i =>
							!submissionData['ORDER_NO'].some(
								orderNo => orderNo.value === i.value
							)
					)
					submissionData['ORDER_NO'].push(...orderNos)
				})
				this.options.submissionData = submissionData
				return list
			}
		}),
		...mapGetters('deliveriesSubmissionList', {
			total: 'total'
		})
	},
	methods: {
		async onDelete(id) {
			this.$store.dispatch('deliveriesSubmissionList/removeFromList', id)
		}
	}
}
</script>

<style lang="scss" scoped>
#deliveries-submission-list-content {
	width: 100%;

	#deliveries-submission-list-table {
		border: 1px solid #ddd;
		border-collapse: collapse;
		margin: auto;
		width: 100%;

		td,
		th {
			border-bottom: 1px solid #ddd;
			text-align: left;
			padding: 2px 6px 2px 2px;
			word-break: break-word;
		}

		th {
			top: 0;
			position: sticky;
			background-color: #fff;
			white-space: pre-wrap;
		}

		td {
			word-wrap: break-word;
			max-width: 125px;
		}

		tr:nth-child(even) {
			background-color: #eee;
		}
	}
}
</style>
