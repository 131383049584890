<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import _ from 'lodash'
import ListPageGen from './ListPageGen.vue'
import Vue from 'vue'
import DeliveriesFormSubmit from './DeliveriesFormSubmit.vue'
import axiosApiClient from '../api/axiosApiClient'

const { VUE_APP_ENV, VUE_APP_VERSION } = process.env

const prepareDeliveryList = async (r, commonForms, clientForms) => {
	let locationId
	const formIds = [...(clientForms[r.clientId] || []), ...commonForms]
	let submissionData
	const {
		clientId,
		clientRefs,
		courier,
		order,
		formSubmissions,
		status,
		meta
	} = r
	const values = _.groupBy(clientRefs, 'type')
	const format = 'MANUAL' // meta.format is always MANUAL for form-submissions via backoffice
	if (status === 'AWAITS_PICKUP') {
		locationId = order.locationId || 'HK_HKG'
		const orderNos = (values['ORDER'] || []).map(i => {
			return {
				value: i.value,
				meta: { clientId, format, rawValue: i.value }
			}
		})
		const parcels = (values['PARCEL'] || []).map(i => {
			return {
				value: i.value,
				meta: { clientId, format, rawValue: i.value }
			}
		})
		submissionData = {
			ORDER_NO: orderNos,
			BARCODE: parcels
		}
	} else if (formSubmissions.length) {
		const submission = formSubmissions.pop()
		locationId = submission.locationId || 'HK_HKG'
		const orderNos = (values['ORDER'] || []).map(i => {
			return {
				value: i.value,
				meta: { clientId, format, rawValue: i.value }
			}
		})
		const parcels = (values['PARCEL'] || []).map(i => {
			return {
				value: i.value,
				meta: { clientId, format, rawValue: i.value }
			}
		})
		submissionData = {
			ORDER_NO: orderNos,
			BARCODE: parcels
		}
	} else {
		locationId = 'HK_HKG'
		const orderNos = (values['ORDER'] || []).map(i => {
			return {
				value: i.value,
				meta: { clientId, format, rawValue: i.value }
			}
		})
		const parcels = (values['PARCEL'] || []).map(i => {
			return {
				value: i.value,
				meta: { clientId, format, rawValue: i.value }
			}
		})
		submissionData = {
			ORDER_NO: orderNos,
			BARCODE: parcels
		}
	}

	let courierDetails
	let courierId
	if (courier) {
		courierId = courier.id
		const phone = (courier.aliases.find(i => i.type === 'PHONE') || {}).id
		courierDetails = `${courierId} | ${phone || '(no phone number)'} | ${
			courier.info.name || '(unknown name)'
		}`
		// simplify courier info display:
		r.courier = _.compact([courier.info.name, phone])
		r.courierDetails = { id: courierId, name: courier.info.name, phone }
	}
	if (meta) {
		r.meta = [...(meta.frames || []), ...(meta.barcodeFormats || [])]
	}

	// consolidate status, hasSla, and isFlagged into a single column:
	r.statusHasSlaIsFlagged = `${r.status}\n${r.hasSla ? 'Has' : 'No'} SLA${
		r.isFlagged ? '\n⚠️' : ''
	}`

	// simplify clientRefs display:
	const cltRefs = _.groupBy(clientRefs, 'type')
	r.clientRefs = []
	const keys = Object.keys(cltRefs).sort()
	for (const key of keys) {
		const refValues = cltRefs[key].map(i => `${i.value} (${key})`)
		r.clientRefs.push(...refValues)
	}

	const deliveryDetails = {
		clientId,
		formIds,
		formId: null,
		courier: courierDetails,
		courierId: courierId,
		locationId,
		submissionData,
		users: [],
		backofficeMeta: { env: VUE_APP_ENV, version: VUE_APP_VERSION }
	}
	r.deliveryDetails = deliveryDetails
	r.formSubmit = new Vue(
		Object.assign({}, DeliveriesFormSubmit, {
			data: () => ({ ...deliveryDetails })
		})
	)
	return r
}

const config = {
	options: {
		canCreateNew: false,
		canEdit: true,
		resource: 'deliveries',
		display: [
			{ key: 'id' },
			{ key: 'clientId', width: '7%' },
			{ key: 'courier', width: '15%' },
			{
				key: 'statusHasSlaIsFlagged',
				name: 'Status,\nHas SLA,\nFlagged(⚠️)',
				width: '10%'
			}, // consolidate status, hasSla, and isFlagged into a single column
			{ key: 'clientRefs', width: '17%' },
			{ key: 'formSubmit' },
			{ key: 'meta', width: '10%' }
		],
		modifier: async data => {
			const response = await axiosApiClient({ url: `/forms?limit=200` })
			let commonForms = []
			const forms = _.groupBy(response.data, 'clientId')
			const clientForms = {}
			for (const [clientId, form] of Object.entries(forms)) {
				if (clientId === 'null') {
					commonForms = form.map(i => i.id)
					continue
				}
				clientForms[clientId] = form.map(i => i.id)
			}
			return Promise.all(
				data.map(r => prepareDeliveryList(r, commonForms, clientForms))
			)
		},
		filterModifier: async data => {
			data.autocompleteFields = [
				{
					name: 'clientIdAndClientRef.clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				},
				{
					name: 'isFlaggedAndClientId.clientId',
					resource: 'clients',
					query: 'id',
					display: 'id'
				}
			]
			return _.mapValues(data, schema => {
				_.unset(schema, 'properties.checkInterim')
				return schema
			})
		}
	}
}

export default {
	name: 'DeliveriesList',
	components: { ListPageGen },
	data: () => config
}
</script>
