<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import DetailsPageGen from './DetailsPageGen.vue'
import WebhookRetry from './WebhookRetry.vue'

const config = {
	options: {
		resource: 'webhooks',
		canEdit: false,
		canDelete: false,
		display: [
			'id',
			'type',
			'clientId',
			'status',
			'input',
			'createdAt',
			'updatedAt'
		],
		modifier: async (ctx, data) => {
			const { status } = data
			const component = new Vue(
				Object.assign({}, WebhookRetry, {
					data: () => ({ status, id: data.id })
				})
			)
			data.status = component
			data.clientId = data.input.responseBody.clientId
			if (data.action === 'FORM_SUBMISSION') {
				data.input.fields = data.input.responseBody.fields.map(f => ({
					[f.tag]: f.values
				}))
				delete data.input.responseBody
			}
			return data
		}
	}
}

export default {
	name: 'WebhookDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
