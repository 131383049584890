<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>

<script>
import axiosApiClient from '../api/axiosApiClient'
import EditPageGen from './EditPageGen.vue'

const config = {
	options: {
		resource: 'form-fields',
		schemaModifier: async data => {
			let response = await axiosApiClient({ url: '/clients?limit=200' })
			const clientIds = response.data.map(i => i.id)
			data.properties.attributes.properties.valueFormats.items.properties.clientId.enum =
				clientIds
			data.properties.options.items.properties.tag.valueInLegend = true

			response = await axiosApiClient({ url: '/locales?limit=200' })
			const locales = response.data.map(i => i.id)
			data.properties.translations.items.properties.locale.enum = locales
			data.properties.translations.items.properties.locale.valueInLegend = true
			return data
		}
	}
}

export default {
	name: 'FormFieldsEdit',
	components: { EditPageGen },
	data: () => config
}
</script>
