var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"deliveries-submission-list"}},[_c('h3',[_vm._v("Submission List")]),_vm._m(0),_c('div',{attrs:{"id":"deliveries-submission-list-content"}},[_c('table',{attrs:{"id":"deliveries-submission-list-table"}},[_c('thead',[_vm._l((_vm.headers),function(m){return [_c('th',{key:m.header,attrs:{"width":m.width ? m.width : null}},[_vm._v(" "+_vm._s(m.header)+" ")])]}),_c('th',{staticStyle:{"width":"3%"}})],2),_vm._l((_vm.deliveriesSubmissionList),function(item){return _c('tr',{key:item.id},[_vm._l((_vm.headers),function(m){return _c('td',{key:m.key},[(Array.isArray(item[m.key]))?_c('div',[_c('div',{attrs:{"id":"divTable"}},[_c('div',{attrs:{"id":"divTableBody"}},_vm._l((item[m.key]),function(v,k){return _c('div',{key:k,attrs:{"id":"divTableRow"}},[(typeof v === 'object')?_c('div',_vm._l((v),function(value,key){return _c('div',{key:key,staticStyle:{"display":"flex","justify-content":"space-between","flex-wrap":"wrap"}},[_c('render-value',{staticStyle:{"font-weight":"bold"},attrs:{"value":`${key}:`}}),(Array.isArray(value))?_c('div',[_c('div',{staticStyle:{"float":"right"},attrs:{"id":"divTable"}},[_c('div',{attrs:{"id":"divTableBody"}},_vm._l((value),function(val,key){return _c('div',{key:key,attrs:{"id":"divTableRow"}},[_c('render-value',{attrs:{"value":val}})],1)}),0)])]):_c('div',[_c('render-value',{attrs:{"value":value}})],1)],1)}),0):_c('div',[_c('render-value',{attrs:{"value":v}})],1)])}),0)])]):(
							typeof item[m.key] === 'object' &&
							item[m.key] !== null &&
							!item[m.key]._isVue
						)?_c('div',_vm._l((item[m.key]),function(value,key){return _c('div',{key:key,staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(key)+": ")]),_c('render-value',{attrs:{"value":value}})],1)}),0):_c('div',[_c('render-value',{attrs:{"value":item[m.key]}})],1)])}),_c('td',[_c('a',{staticStyle:{"font-size":"23px"},attrs:{"title":"delete","href":"javascript:void(0)"},on:{"click":function($event){return _vm.onDelete(item.id)}}},[_vm._v(" ❌ ")])])],2)})],2),_c('h3',[_vm._v("Form submit")]),_vm._m(1),_c('div',{staticStyle:{"width":"50%","margin-top":"10px"}},[_c('deliveries-form-submit',{key:_vm.total,attrs:{"options":_vm.options}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',[_vm._v("Deliveries in this list are marked for form submission")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',[_vm._v(" Submit a form with all deliveries in the Submission List above ")])])
}]

export { render, staticRenderFns }