<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import moment from 'moment-timezone'
import ListPageGen from './ListPageGen.vue'
import _ from 'lodash'
import Vue from 'vue'
import FormSubmissionsNewFormIdSelect from '@/components/FormSubmissionsNewFormIdSelect.vue'

const prepareFormSubmissionList = async data => {
	let info = []
	if (data.meta) {
		const { manualBarcode, os = 'unknown', env = 'unknown' } = data.meta
		info = [
			{ 'All Scan': !manualBarcode ? '✅' : '❌' },
			{ 'ENV-OS': `${env}-${os.split(';')[0]}` }
		]
	}

	if (data.tag.startsWith('CROSSDOCK_')) {
		const statusIcons = {
			REVIEWING: '🟡',
			REJECTED: '🔴',
			APPROVED: '🟢'
		}

		let dockId = '',
			dockStatus = ''
		for (const { tag, values } of data.fields) {
			if (tag === 'DOCKID') {
				dockId = values[0]
			}
			if (tag === 'DOCKSTATUS') {
				dockStatus = values[0]
			}
			if (dockId && dockStatus) {
				break
			}
		}
		if (dockStatus) {
			info.push({
				'Dock Status': `${dockStatus} ${statusIcons[dockStatus]}`
			})
		}
		if (dockId) {
			info.push({ 'Dock ID': dockId.substring(0, 7).toUpperCase() })
		}
	}

	let { clientRefs } = data
	clientRefs = _.groupBy(clientRefs, 'type')
	data.clientRefs = []
	const keys = Object.keys(clientRefs).sort()
	for (const key of keys) {
		const clientRef = { [key]: clientRefs[key].map(i => i.value) }
		data.clientRefs.push(clientRef)
	}

	data.user = _.compact([
		_.get(_.find(_.get(data, 'user.aliases'), { type: 'PHONE' }), 'id'),
		_.get(data, 'user.info.name')
	])

	const timezone = moment.tz.guess()
	data.createdAt = moment
		.tz(data.createdAt, timezone)
		.format('YYYY-MM-DD HH:mm')
	data.submittedAt = moment
		.tz(data.submittedAt, timezone)
		.format('YYYY-MM-DD HH:mm')
	data.createdAtSubmittedAt = `${data.createdAt}\n(${data.submittedAt})`

	return Object.assign({}, data, { info })
}

const config = {
	options: {
		resource: 'form-submissions',
		display: [
			{ key: 'id' },
			{ key: 'formId' },
			{ key: 'info', width: '20%' },
			{ key: 'clientId', name: 'Clients' },
			{ key: 'clientRefs' },
			{ key: 'user' },
			{
				key: 'createdAtSubmittedAt',
				name: 'Created (Submitted)',
				isDateTime: true,
				width: '20%'
			}
		],
		canCreateNew: false,
		canEdit: false,
		canDelete:
			process.env.VUE_APP_ENABLE_LLMP_575_DELETE_FORM_SUBMISSIONS ===
			'true',
		syncEnabled: false,
		exportEnabled: true,
		canCopyForNew: fs => {
			return (
				process.env.VUE_APP_ENABLE_LLMP_575_DELETE_FORM_SUBMISSIONS ===
				'true'
			)
		},
		onCopyForNew: async (router, item) => {
			return router.push({
				path: '/form-submissions/new',
				query: {
					formSubmissionsId: item.id,
					isDeleted: item.deletedAt !== null
				}
			})
		},
		customComponents: [new Vue(FormSubmissionsNewFormIdSelect)],
		modifier: async data =>
			Promise.all(data.map(r => prepareFormSubmissionList(r))),
		filterModifier: async data => {
			// fix clientRefs schemas
			for (const key of [
				'clientIdAndClientRef',
				'clientRefAndIsDeleted'
			]) {
				data[key].properties.clientRefsValue =
					data[key].properties['clientRefs.value']
				delete data[key].properties['clientRefs.value']
				data[key].prefix = ['clientRefsValue']
				data[key].required = ['clientId', 'clientRefsValue']
			}

			return data
		},
		applyFilterModifier: async data => {
			if (data.clientRefsValue) {
				data['clientRefs.value'] = data.clientRefsValue
				delete data.clientRefsValue
			}
			return data
		}
	}
}

export default {
	name: 'FormSubmissionsList',
	components: { ListPageGen },
	data: () => config
}
</script>
