<script>
import EditPageGen from '@/components/EditPageGen.vue'
import ApiOrderEditForm from './components/ApiOrderEditForm.vue'
import axiosApiClient from '@/api/axiosApiClient'
import SideNav from '@/components/SideNav.vue'
import { Notify } from 'quasar'
import { fixDateOffset, changeDateInputTz } from '@/lib/plugins/date'
import { getLocations } from '@/api/locations-api'
import { getOrder } from '@/api/orders-api'
import _ from 'lodash'
import { featureFlags } from '@/config'
import * as Sentry from '@sentry/vue'

const config = {
	options: {
		name: 'Orders',
		resource: 'orders',
		schemaModifier: async (schema, data) => {
			delete schema.properties.userIds
			delete schema.properties.orderImportId
			delete schema.properties.requestSpreadsheetSubmissionId
			const locations = (await getLocations()).data
			schema.properties.locationId.enum = locations.map(i => i.id)

			const tagsResponse = await axiosApiClient({
				url: `/order-tag-keys?limit=100`
			})
			const tags = tagsResponse.data.map(t => t.id)
			if (tags.length > 0) {
				schema.properties.tags.items.properties.key.enum = tags
			} else {
				delete schema.properties.tags
			}

			// change notes schema depends on api-wrapper order / CSV order
			const notesType = data.orderDisplayId !== null ? 'object' : 'string'
			const notesSchema = _.find(schema.properties.notes.anyOf, {
				items: { type: notesType }
			})
			schema.properties.notes = {
				...schema.properties.notes,
				...notesSchema
			}
			return schema
		},
		preSubmitModifier: async function (data, opt) {
			const { data: previousData } = await axiosApiClient({
				url: `${opt.url}/${opt.recordId}`
			})
			if (
				previousData.status === 'CANCELED' &&
				data.status === 'CANCELED'
			) {
				Notify.create(
					'You cannot patch the CANCELED status when order is canceled'
				)
				throw Error('patch CANCELED status when order is canceled')
			} else {
				// fix offset problem by bootstrap-datetimepicker
				const { locationId, pickupAt, deliveryBy } = data
				const tz = this.locations[locationId]
				const newPickupAt = fixDateOffset(pickupAt, tz)
				const newDeliveryBy = fixDateOffset(deliveryBy, tz)
				const newExtras = [
					{
						...(previousData.extras[0] || {}),
						...(data.extras[0] || {})
					}
				]

				return {
					...data,
					extras: newExtras,
					pickupAt: newPickupAt,
					deliveryBy: newDeliveryBy
				}
			}
		},
		locations: {}
	},
	locationSelect: null
}

export default {
	name: 'OrdersEdit',
	components: { EditPageGen, ApiOrderEditForm, SideNav },
	data: function () {
		return {
			...config,
			apiV3Order: null,
			featureFlags,
			ready: false,
			Sentry
		}
	},
	created: async function () {
		try {
			this.apiV3Order = await getOrder(this.$route.params.id)
			this.ready = true
		} catch (e) {
			if (_.get(e, 'response.status') !== 404) {
				Sentry.captureMessage('Order:Cannot find order', {
					level: 'error',
					extra: {
						error: {
							orderId: this.$route.params.id,
							stack: e.stack
						}
					}
				})
			}
		}
	},
	beforeDestroy: function () {
		if (this.locationSelect) {
			this.locationSelect.removeEventListener(
				'change',
				this.onLocationChange
			)
		}
	},
	methods: {
		onFormInitialized: async function () {
			const locations = (await getLocations()).data
			locations.forEach(location => {
				this.options.locations[location.id] = location.timezone
			})

			this.locationSelect = document.querySelector(
				'select[name="locationId"]'
			)
			const locationId = this.locationSelect.value
			this.locationSelect.addEventListener(
				'change',
				this.onLocationChange
			)

			// Fire to change tz on page load
			this.changeDateFieldsTz(locationId)
		},
		/**
		 * Listen value change of location select
		 *
		 * @param event
		 */
		onLocationChange: async function (event) {
			this.changeDateFieldsTz(event.target.value)
		},
		/**
		 * Update date fields manually when location changed
		 *
		 * @param locationId
		 */
		changeDateFieldsTz: function (locationId) {
			const tz = this.options.locations[locationId]
			changeDateInputTz('pickupAt', tz)
			changeDateInputTz('deliveryBy', tz)
		}
	}
}
</script>

<template>
	<div>
		<div v-if="ready">
			<ApiOrderEditForm
				v-if="apiV3Order && apiV3Order.orderDisplayId"
				:details="apiV3Order"
			/>
			<edit-page-gen
				v-else
				:options="options"
				:on-form-initialized="onFormInitialized"
			/>
		</div>
		<div v-else>
			<SideNav />
		</div>
	</div>
</template>

<style>
.aliasType {
	width: 10%;
	float: left;
	margin-right: 10px;
}
.aliasValue {
	width: 50%;
	float: left;
}
.userId {
	clear: left;
	display: block;
}
</style>
