<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'

const config = {
	options: {
		resource: 'api-tokens',
		schemaModifier: async data => {
			data.required = ['userId']
			data.autocompleteFields = [
				{
					name: 'userId',
					resource: 'users',
					query: 'aliases.id',
					display: data => {
						data = {
							id: data.id,
							alias: data.aliases.map(i => i.id)
						}
						let suggestions = JSON.stringify(data)
						suggestions = suggestions.substring(
							1,
							suggestions.length - 1
						)
						return suggestions
					}
				}
			]
			return data
		},
		modifier: async data => {
			data.userId = JSON.parse(`{ ${data.userId} }`).id.toString()
			return data
		}
	}
}

export default {
	name: 'ApiTokenNew',
	components: { NewPageGen },
	data: () => config
}
</script>
