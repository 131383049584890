<script>
import _ from 'lodash'
import CommonForm from '@/components/CommonForm.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import FormArray from '@/components/FormArray.vue'

import { defaultTimezone } from './timezone'
const { VUE_APP_LLP_15585_LOCATION_PAGE_SHIFT_CONFIG } = process.env

export default {
	name: 'FormSchema',
	components: {
		CommonForm,
		FormSelect,
		FormArray,
		FormInput
	},
	props: {
		submitLabel: {
			type: String,
			default: 'Create'
		},
		itemData: {
			type: Object,
			default: () => {}
		},
		localeOptions: {
			type: Array,
			default: () => []
		},
		submitFunc: {
			type: Function,
			default: () => {}
		},
		canSubmit: {
			type: Boolean,
			required: false
		},
		validateFunc: {
			type: Function,
			default: () => false
		}
	},
	data() {
		return {
			validated: this.submitLabel === 'Update',
			defaultTimezone,
			id: '',
			timezone: _.get(defaultTimezone, 'enum.0'),
			locales: [],
			translations: [],
			phoneFormats: {
				regexpIntl: '',
				regexpLocal: '',
				templateIntl: '',
				templateLocal: '',
				codePrefix: ''
			},
			geo: {
				lat: '',
				lng: '',
				radius: null
			},
			shifts: {
				cutOffAt: '',
				timeRanges: [],
				districts: [],
				generationEnabled: false
			},
			schema: {
				locales: {
					locale: {
						type: 'select',
						label: 'local code',
						options: this.localeOptions,
						rules: [
							val =>
								val.match(`^[a-z]{2}-[A-Z]{2}$`) ||
								`Please select a locale`
						],
						required: true
					}
				},
				translations: {
					locale: {
						type: 'select',
						label: 'local code',
						options: this.localeOptions,
						rules: [
							val =>
								val.match(`^[a-z]{2}-[A-Z]{2}$`) ||
								`Please select a locale`
						],
						required: true
					},
					value: {
						type: 'input',
						label: 'value',
						rules: [val => !!val || `Please insert a value`],
						required: true
					}
				},
				shiftDistricts: {
					districts: {
						type: 'input',
						label: 'Districts',
						rules: [val => !!val || `District cannot empty`],
						required: false
					}
				},
				shiftTimeRanges: {
					startAt: {
						type: 'input',
						label: 'Start At',
						rules: this.$validator.time24Format('Start At'),
						required: true
					},
					endAt: {
						type: 'input',
						label: 'End At',
						rules: this.$validator.time24Format('End At'),
						required: true
					}
				}
			},
			VUE_APP_LLP_15585_LOCATION_PAGE_SHIFT_CONFIG
		}
	},
	watch: {
		localeOptions: function (newVal) {
			this.schema.locales.locale.options = newVal
			this.schema.translations.locale.options = newVal
		},
		itemData: async function (newVal) {
			this.id = _.get(newVal, 'id', '')
			this.timezone = _.get(newVal, 'timezone', '')
			this.phoneFormats = {
				...this.phoneFormats,
				..._.get(newVal, 'phone_formats')
			}
			this.geo = { ...this.geo, ..._.get(newVal, 'geo') }

			this.locales = []
			const newLocales = _.get(newVal, 'locales', [])
			for (const index in newLocales) {
				this.locales.push({ locale: newLocales[index] })
			}

			this.translations = []
			const newTranslations = _.get(newVal, 'translations', [])
			for (const index in newTranslations) {
				this.translations.push(newTranslations[index])
			}

			this.shifts.cutOffAt =
				newVal.shiftRegistrationCutoffAt || '17:00:00'
			this.shifts.timeRanges = newVal.shiftTimeRanges || []
			this.shifts.districts = (newVal.shiftDistricts || []).map(d => ({
				districts: d
			}))
			this.shifts.generationEnabled =
				newVal.shiftGenerationEnabled || false

			await this.listRequiredInit()
		}
	},
	methods: {
		async listRequiredInit() {
			//	Locales: required >= one
			if (_.isEmpty(this.locales)) {
				this.locales.push({ locale: '' })
			}
		},
		async onSubmit() {
			if (this.submitLabel !== 'Update' && !this.validated) {
				return
			}

			try {
				await this.submitFunc({
					...this.$data,
					shifts: undefined,
					shiftRegistrationCutoffAt: this.$data.shifts.cutOffAt,
					shiftTimeRanges: this.$data.shifts.timeRanges,
					shiftDistricts: this.$data.shifts.districts.map(
						d => d.districts
					),
					shiftGenerationEnabled: this.$data.shifts.generationEnabled
				})
				alert('Successfully submitted!')
				return this.$router.push({
					path: `/${_.split(_.get(this.$route, 'path'), '/')[1]}`
				})
			} catch (e) {
				const errorDetail = _.get(
					e,
					'response.data.errors[0].detail',
					''
				)
				alert(`Submitted Failed!${errorDetail && ` \n${errorDetail}`}`)
			}
		},
		async onReset() {
			this.id = _.get(this.itemData, 'id', '')
			this.timezone = _.get(this.itemData, 'timezone', '')
			this.locales = _.map(
				_.get(this.itemData, 'locales', []),
				locale => ({
					locale: locale
				})
			)
			this.translations = JSON.parse(
				JSON.stringify(_.get(this.itemData, 'translations', []))
			)
			this.phoneFormats = {
				...this.phoneFormats,
				..._.get(this.itemData, 'phone_formats')
			}
			this.geo = { ...this.geo, ..._.get(this.itemData, 'geo') }

			this.shifts = {
				cutOffAt: this.itemData.shiftRegistrationCutoffAt,
				timeRanges: this.itemData.shiftTimeRanges || [],
				districts: (this.itemData.shiftDistricts || []).map(d => ({
					districts: d
				})),
				generationEnabled: this.itemData.shiftGenerationEnabled
			}

			await this.listRequiredInit()
		},
		onClickAdd(index, newObj = {}) {
			if (_.has(this.$data, index)) {
				this[index].push(newObj)
			}
		},
		async onClickRemove(index) {
			if (!_.isEmpty(this[index])) {
				this[index].pop()
			}

			await this.listRequiredInit()
		},
		async validate(locationCode) {
			if (
				locationCode &&
				locationCode.match(`^[A-Z]{2}(_[A-Z0-9]{3})?$`)
			) {
				this.validated = await this.validateFunc(locationCode)
			} else {
				alert('location code invalid')
			}
		},
		addShiftDistrict(district) {
			this.shifts.districts.push(district)
		},
		removeShiftDistrict() {
			this.shifts.districts.pop()
		},
		addShiftTimeRange(timeRange) {
			this.shifts.timeRanges.push(timeRange)
		},
		removeShiftTimeRange() {
			this.shifts.timeRanges.pop()
		}
	}
}
</script>

<template>
	<common-form
		submit-label="Submit"
		layout="vertical"
		:submit="onSubmit"
		:reset="onReset"
		:display-btn="!!validated"
	>
		<!-- Location Code -->
		<div id="validate-location-code">
			<form-input
				v-model="id"
				label="Location code"
				:disable="validated"
				:rules="$validator.lowcode('Location code')"
			/>

			<q-btn
				label="Validate with core system"
				no-caps
				color="orange-9"
				:disable="validated"
				@click="validate(id)"
			/>
		</div>

		<span v-show="validated" id="location-form">
			<!-- Timezone -->
			<div>
				<form-select
					v-model="timezone"
					label="Timezone"
					:options="defaultTimezone.enum"
					:rules="$validator.required('Timezone')"
				/>
			</div>

			<!-- Locales -->
			<div>
				<q-expansion-item
					:label="`Locales - total(${locales.length})`"
					expand-separator
					default-opened
				>
					<form-array
						:data-list="locales"
						:data-schema="schema.locales"
						@onAdd="tempObj => onClickAdd('locales', tempObj)"
						@onRemove="onClickRemove('locales')"
					/>
				</q-expansion-item>
			</div>

			<!-- Translations -->
			<div>
				<q-expansion-item
					:label="`Translations - total(${translations.length})`"
					expand-separator
					default-opened
				>
					<form-array
						:data-list="translations"
						:data-schema="schema.translations"
						@onAdd="tempObj => onClickAdd('translations', tempObj)"
						@onRemove="onClickRemove('translations')"
					/>
				</q-expansion-item>
			</div>

			<!-- Phone Formats -->
			<div>
				<q-expansion-item
					label="Phone Formats"
					expand-separator
					default-opened
				>
					<q-list separator bordered class="rounded-borders">
						<form-input
							v-model="phoneFormats.regexpIntl"
							label="regexp intl*"
							:rules="$validator.required('regexp intl')"
						/>
						<form-input
							v-model="phoneFormats.regexpLocal"
							label="regexp intl*"
							:rules="$validator.required('regexp intl')"
						/>
						<form-input
							v-model="phoneFormats.templateIntl"
							label="template intl*"
							:rules="$validator.required('template intl')"
						/>
						<form-input
							v-model="phoneFormats.templateLocal"
							label="template local*"
							:rules="$validator.required('template local')"
						/>
						<form-input
							v-model="phoneFormats.codePrefix"
							label="code prefix"
						/>
					</q-list>
				</q-expansion-item>
			</div>

			<!-- Geo -->
			<div>
				<q-expansion-item label="Geo" expand-separator default-opened>
					<q-list separator bordered class="rounded-borders">
						<form-input
							v-model="geo.lat"
							label="lat*"
							:rules="$validator.lat('lat')"
						/>
						<form-input
							v-model="geo.lng"
							label="lng*"
							:rules="$validator.lng('lng')"
						/>
						<form-input
							v-model="geo.radius"
							label="radius*"
							:rules="$validator.nonEmptyNumber('radius')"
						/>
					</q-list>
				</q-expansion-item>
			</div>

			<!-- Shifts -->
			<div v-if="VUE_APP_LLP_15585_LOCATION_PAGE_SHIFT_CONFIG === 'true'">
				<q-expansion-item
					label="Shifts"
					expand-separator
					default-opened
				>
					<q-list separator bordered class="rounded-borders">
						<q-checkbox
							left-label
							size="sm"
							v-model="shifts.generationEnabled"
							label="Enable Generation"
						/>

						<form-input
							v-model="shifts.cutOffAt"
							label="Cut off at"
							:rules="
								$validator.time24WithSecondsFormat('Cut off at')
							"
						/>

						<h4 class="text-h4">Time Ranges</h4>
						<form-array
							:data-list="shifts.timeRanges"
							:data-schema="schema.shiftTimeRanges"
							@onAdd="timeRange => addShiftTimeRange(timeRange)"
							@onRemove="removeShiftTimeRange()"
						/>

						<h4 class="text-h4">Districts</h4>
						<form-array
							:data-list="shifts.districts"
							:data-schema="schema.shiftDistricts"
							@onAdd="district => addShiftDistrict(district)"
							@onRemove="removeShiftDistrict()"
						/>
					</q-list>
				</q-expansion-item>
			</div>
		</span>
	</common-form>
</template>

<style lang="scss" scoped>
#validate-location-code {
	display: -webkit-inline-box;

	::v-deep label {
		width: 200px;
	}

	::v-deep button {
		margin: 4px 10px;
	}
}
#location-form {
	width: 60%;
	padding: 5px 10px;

	> div {
		margin-top: 16px;
	}

	::v-deep .q-list--separator {
		padding: 10px;
	}

	::v-deep label {
		width: 100%;
		margin: 10px 0 24px 0;
		padding-bottom: 10px;
	}
}

h4 {
	font-size: 110%;
}
</style>
