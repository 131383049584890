<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'form-fields',
		display: [
			{ key: 'clientId' },
			{ key: 'tag' },
			{ key: 'type' },
			{ key: 'translations' },
			{ key: 'createdAt' }
		],
		comparisonDisplay: ['clientId', 'tag', 'type'],
		modifier: async data =>
			data.map(r => {
				const translations = r.translations.map(t => ({
					[t.locale]: t.value
				}))
				return Object.assign({}, r, { translations })
			}),
		filterModifier: async data => {
			data.client = Object.assign({}, data.id)
			data.client.properties.id.title = 'Client'
			data.autocompleteFields = [
				{
					name: 'client.id', // name of the field in jsonschema eg. data.client.properties.id = client.id
					resource: 'clients',
					query: 'id',
					display: 'id' // field from response to be displayed in the suggestion
				}
			]
			delete data.id
			return data
		}
	}
}

export default {
	name: 'FormFieldsList',
	components: { ListPageGen },
	data: () => config
}
</script>
