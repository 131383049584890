<template>
	<div>
		<new-page-gen :options="options" />
	</div>
</template>

<script>
import NewPageGen from './NewPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'

const config = {
	options: {
		resource: 'form-fields',
		schemaModifier: async data => {
			let response = await axiosApiClient({ url: '/clients?limit=200' })
			const clientIds = response.data.map(i => i.id)
			data.properties.attributes.properties.valueFormats.items.properties.clientId.enum =
				clientIds
			// set header for Options tabs
			data.properties.options.items.properties.tag.valueInLegend = true

			response = await axiosApiClient({ url: '/locales?limit=200' })
			const locales = response.data.map(i => i.id)
			data.properties.translations.items.properties.locale.enum = locales

			data.autocompleteFields = [
				{
					name: 'clientId', // name of the field in jsonschema
					resource: 'clients',
					query: 'id',
					display: 'id' // field from response to be displayed in the suggestion
				}
			]
			return data
		}
	}
}

export default {
	name: 'FormFieldsNew',
	components: { NewPageGen },
	data: () => config
}
</script>
