<script>
import _ from 'lodash'
import { Notify } from 'quasar'
import EditPageGen from './EditPageGen.vue'
import axiosApiClient from '../api/axiosApiClient'
import { featureFlags } from '@/config'

let clientRefFields

const config = {
	options: {
		resource: 'forms',
		modifier: async data => {
			data.attributes.crossdock = data.attributes.crossdock
				? [data.attributes.crossdock]
				: []
			if (data.clientId === null) {
				delete data.clientId
			}
			clientRefFields = data.fields
				.map(i =>
					['BARCODE', 'TEXT', 'TEXTAREA'].includes(i.type)
						? i.tag
						: undefined
				)
				.filter(i => i)
			// To make existing data compatible with new jsonschema
			if (typeof data.attributes.zapierUrl === 'string') {
				const url = data.attributes.zapierUrl
				data.attributes.zapierUrl = [
					{
						url,
						location: null
					}
				]
			}

			// Non-barcode delivery
			// Set default value if not true, to fix UI issue
			// (UI will show "add" and "remove" button if no value)
			if (data.attributes.nonBarcodeDeliveryEnabled !== true) {
				data.attributes.nonBarcodeDeliveryEnabled = false
			}
			// Set default value for forms_form_fields
			data.fields
				.filter(f => f.isNonBarcodeDelivery !== true)
				.forEach(f => (f.isNonBarcodeDelivery = false))

			return data
		},
		schemaModifier: async (schema, data) => {
			// set clientRef fields enum
			if (clientRefFields.length > 0) {
				schema.properties.attributes.properties.clientRefFields.items.properties.tag.enum =
					_.uniq(clientRefFields)
			}

			// set header for translations tabs
			let response = await axiosApiClient({ url: `/locales?limit=100` })
			const locales = response.data.map(i => i.id)
			schema.properties.translations.items.properties.locale.enum =
				locales
			schema.properties.translations.items.properties.locale.valueInLegend = true
			schema.properties.attributes.properties.icon.file = true

			const { crossdock } = schema.properties.attributes.properties
			schema.properties.attributes.properties.crossdock = {
				type: 'array',
				items: crossdock,
				maxItems: 1
			}

			const fieldIds = [],
				fieldTags = []

			response = await axiosApiClient({
				url: `/form-fields?id=COMMON_*&limit=100`
			})
			response.data.forEach(({ id, tag }) => {
				fieldIds.push(id)
				fieldTags.push(tag)
			})

			if (data.clientId) {
				response = await axiosApiClient({
					url: `/form-fields?id=${data.clientId}_*&limit=100`
				})
				response.data.forEach(({ id, tag }) => {
					fieldIds.push(id)
					fieldTags.push(tag)
				})
			}

			// set fields enum
			schema.properties.fields.items.properties.id.enum = fieldIds
			schema.properties.attributes.properties.crossdock.items.properties.qrFieldTags.items.enum =
				_.uniq(fieldTags)

			if (!featureFlags.LLP_18517_NON_BARCODE_DELIVERY) {
				delete schema.properties.attributes.properties
					.nonBarcodeDeliveryEnabled
				delete schema.properties.fields.items.properties
					.isNonBarcodeDelivery
			}

			return schema
		},
		preSubmitModifier: async data => {
			const fieldsId = _.map(data.fields, 'id')
			if (_.uniq(fieldsId).length !== fieldsId.length) {
				Notify.create('Fields should NOT have duplicate items')
				throw Error('Fields should NOT have duplicate items')
			}
			if (data.attributes.bgColor === '') {
				delete data.attributes.bgColor
			}
			if (data.attributes.crossdock) {
				data.attributes.crossdock = data.attributes.crossdock[0]
			}
			return data
		}
	}
}

export default {
	name: 'FormFieldsEdit',
	components: { EditPageGen },
	data: () => config,
	errorCaptured(err) {
		const knownErrors = [
			`Max 1 'ORDER' type client reference field per form`,
			'You can only set 1 non-barcode field in a form.',
			'You can only select non-barcode delivery for BARCODE / ORDER_NO field.',
			'You need to select or create a barcode field to enable non-barcode delivery.'
		]
		if (typeof err === 'string' && knownErrors.includes(err)) {
			return false
		}
	}
}
</script>

<template>
	<div>
		<edit-page-gen :options="options" />
	</div>
</template>
