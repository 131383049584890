<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment-timezone'
import Link from '@/components/Link.vue'
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'api-tokens',
		canDelete: false,
		canEdit: true,
		modifier: async (ctx, data, store) => {
			const getTokenById = store.getters['apiTokens/getTokenById']
			const apiToken = getTokenById(data.id)
			if (apiToken) {
				data.token = apiToken.token
			}
			data.issuedAt = moment
				.unix(data.issuedAt)
				.format('YYYY-MM-DD HH:mm')
			data.expireAt = moment
				.unix(data.expireAt)
				.format('YYYY-MM-DD HH:mm')
			const createLink = value =>
				new Vue({
					...Link,
					data: () => ({ href: `/#/users/${value}`, value })
				})
			data.userId = createLink(data.userId)
			return data
		}
	}
}

export default {
	name: 'ApiTokenDetails',
	components: { DetailsPageGen },
	data: () => config,
	beforeRouteLeave(to, from, next) {
		this.$store.dispatch('apiTokens/removeFromList', this.$route.params.id)
		next()
	}
}
</script>
