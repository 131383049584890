<template>
	<div class="form-submissions-new">
		<div class="form-submissions-select-form">
			<div class="form-group jsonform-required">
				<label>Select form for new submission</label>
				<div class="controls">
					<select
						id="jsonform-5-elt-locations"
						v-model="formId"
						class=""
						required="required"
					>
						<option
							v-for="form in allForms"
							:key="form.id"
							:value="form.id"
						>
							{{ form.id }}
						</option>
					</select>
					<button
						class="btn btn-primary"
						:disabled="!formId"
						@click="createNew"
					>
						Create New
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axiosApiClient from '@/api/axiosApiClient'

export default {
	name: 'FormSubmissionsNewFormIdSelect',
	data() {
		const config = {
			allForms: [],
			formId: null
		}

		const initData = async () => {
			// need to increase limit when we see that number of forms is nearing 100
			const formResponse = await axiosApiClient({
				url: `/forms?limit=200`
			})
			config.allForms = formResponse.data
		}

		initData()

		return config
	},
	methods: {
		async createNew() {
			if (this.$data.formId === null) {
				return
			}
			await this.$router.push({
				path: '/form-submissions/new',
				query: { formId: this.$data.formId }
			})
		}
	}
}
</script>

<style scoped>
.form-submissions-new {
	display: inline-block;
	position: relative;
	width: 100%;
}

.form-submissions-select-form .form-group {
	margin-bottom: 10px;
	margin-right: 15px;
	float: left;
}
</style>
