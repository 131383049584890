<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'courier-menu',
		recordId: '', // there's only one resource for courier-menu
		canDelete: false
	}
}

export default {
	name: 'CourierMenuDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
