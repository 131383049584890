<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		name: 'Order Spreadsheet Formats',
		resource: 'order-spreadsheet-formats',
		display: [
			'id',
			'clientId',
			'tag',
			'attributes',
			'createdAt',
			'updatedAt'
		]
	}
}

export default {
	name: 'OrderSpreadsheetFormatsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
