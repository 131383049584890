<template>
	<details-page-gen :options="options" />
</template>

<script>
import { rolePermissionCheck } from '../../permission/rolePermission'
import DetailsPageGen from '@/components/DetailsPageGen.vue'

const config = {
	options: {
		resource: 'locations',
		canEdit: rolePermissionCheck('locations', 'edit'),
		canDelete: rolePermissionCheck('locations', 'delete')
	}
}

export default {
	name: 'LocationDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
