<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import { postEditOrdersInfo } from '@/api/orders-info-edit-api'
import { getClients } from '@/api/clients-api'
import { getOrderSpreadsheetFormats } from '@/api/order-spreadsheet-formats-api'
import Layout from '@/components/Layout.vue'
import { featureFlags } from '@/config'

const errorMessageMap = {
	'Invalid CSV Format': 'Invalid CSV Format',
	'You cannot correct orders for this client':
		'You cannot correct orders for this client',
	'Too many records': `You can't update more than 1000 records`,
	'Please submit valid form': 'Please submit valid form',
	'Invalid CSV format': 'Invalid CSV format',
	'Internal Server Error': 'Failed to update order info'
}

export default {
	name: 'OrdersInfoEdit',
	components: { Layout },
	data() {
		return {
			filter: {
				clientId: null,
				deliveryStage: 'LAST_MILE',
				orderSpreadsheetFormatId: null,
				file: null
			},
			optionsData: {
				clientOptions: [],
				deliveryStageOptions: ['LAST_MILE', 'FIRST_MILE'],
				orderSpreadsheetFormatOptions: []
			},
			filterLimit: {
				clientsFilter:
					process.env.VUE_APP_ENV === 'ldev'
						? ['FEDEX', 'ACME']
						: ['FEDEX'],
				spreadsheetFormatsFilter:
					process.env.VUE_APP_ENV === 'ldev'
						? ['FEDEX_LASTMILE_202210', 'COMMON_PLACE_ORDER_V2']
						: ['FEDEX_LASTMILE_202210'],
				deliveryStagesFilter: ['LAST_MILE']
			}
		}
	},
	async created() {
		if (featureFlags.DEMAND_9230_RESET_ORDERS_INFO_EDIT_PAGE) {
			this.resetState()
		}
		this.optionsData.clientOptions = ((await getClients()) || []).filter(
			({ id }) => this.filterLimit.clientsFilter.includes(id)
		)

		this.optionsData.orderSpreadsheetFormatOptions = _.intersection(
			((await getOrderSpreadsheetFormats()) || []).map(i => i.id),
			this.filterLimit.spreadsheetFormatsFilter
		)

		this.optionsData.deliveryStageOptions = _.intersection(
			this.optionsData.deliveryStageOptions,
			this.filterLimit.deliveryStagesFilter
		)
	},
	computed: {
		...mapState('ordersInfoEdit', [
			'progressMessage',
			'errorMessageMap',
			'isPolling',
			'signedUrl'
		]),
		disabledUpload() {
			return !_.every(
				[...Object.values(this.filter), !this.isPolling],
				Boolean
			)
		}
	},
	methods: {
		...mapActions('ordersInfoEdit', [
			'pollingDownloadOrderInfoEditCsv',
			'resetState'
		]),
		async onChange() {
			this.data = {}
			this.error = {}
		},
		async importFile(event) {
			try {
				this.filter.file = event.target.files[0]
			} catch (e) {
				alert('import file error')
			}
		},
		async upload() {
			if (featureFlags.DEMAND_9230_RESET_ORDERS_INFO_EDIT_PAGE) {
				this.resetState()
			}
			const { clientId, deliveryStage, orderSpreadsheetFormatId, file } =
				this.filter
			const locationId = _.get(
				_.find(this.optionsData.clientOptions, {
					id: clientId
				}),
				'locations.0'
			)
			const params = {
				clientId,
				orderSpreadsheetFormatId,
				deliveryStage,
				locationId
			}
			try {
				const data = new FormData()
				data.append('spreadsheet', file)
				const ordersInfoEditRequestId = await postEditOrdersInfo(
					params,
					data
				)
				this.pollingDownloadOrderInfoEditCsv(ordersInfoEditRequestId)
			} catch (e) {
				const errorDetail = _.get(e, 'response.data.errors.0.detail')
				const errorMessage = errorMessageMap[errorDetail] || errorDetail
				alert(errorMessage)
			}
		}
	}
}
</script>

<template>
	<layout class-name="orders-info-edit" title="Orders Info Edit">
		<div class="form-info-edit">
			<div
				class="form-group jsonform-error-formId---formId jsonform-required"
			>
				<label>Client</label>
				<div class="controls">
					<select
						id="jsonform-5-elt-clientId"
						v-model="filter.clientId"
						class="form-control"
						required="required"
						@change="onChange"
					>
						<option
							v-for="client in optionsData.clientOptions"
							:key="client.id"
						>
							{{ client.id }}
						</option>
					</select>
				</div>
			</div>
			<div
				class="form-group jsonform-error-formId---formId jsonform-required"
			>
				<label>Spreadsheet Format</label>
				<div class="controls">
					<select
						id="jsonform-5-elt-clientId"
						v-model="filter.orderSpreadsheetFormatId"
						class="form-control"
						required="required"
						@change="onChange"
					>
						<option
							v-for="spreadsheetFormat in optionsData.orderSpreadsheetFormatOptions"
							:key="spreadsheetFormat"
						>
							{{ spreadsheetFormat }}
						</option>
					</select>
				</div>
			</div>
			<div class="form-group jsonform-error-formId---formId">
				<label>Delivery Stage</label>
				<div class="controls">
					<select
						id="jsonform-5-elt-delivery-stage"
						v-model="filter.deliveryStage"
						class="form-control"
						@change="onChange"
					>
						<option
							v-for="stage in optionsData.deliveryStageOptions"
							:key="stage"
						>
							{{ stage }}
						</option>
					</select>
				</div>
			</div>
			<div
				class="form-group jsonform-error-formId---formId jsonform-required"
			>
				<div class="controls">
					<input type="file" @change="importFile($event)" />
				</div>
			</div>
			<button
				class="btn btn-primary"
				:disabled="disabledUpload"
				@click="upload"
			>
				Upload
			</button>
		</div>
		<h3 id="progress-message">{{ progressMessage }}</h3>
		<a :href="signedUrl" id="result-url" v-if="signedUrl">
			<h5>Result CSV Url</h5>
		</a>
	</layout>
</template>

<style lang="scss" scoped>
.form-info-edit {
	width: 300px;
}
</style>
